/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useState, useEffect } from "react";
import axios from "axiosConfig.js";
import { checkJwt, formatDate } from "helper.js";
import { useParams } from "react-router-dom";

const PlaceReports = () => {
  const [reports, setReports] = useState([]);
  const [max, setMax] = useState(1);
  const [count, setCount] = useState(1);
  const [reportModal, setReportModal] = useState({});
  const [deleteModal, setDeleteModal] = useState({});
  const [reportId, setReportId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const urlParams = useParams();
  const [selectedReport, setSelectedReport] = useState({
    user: {
      name: "",
    },
    type: "",
    description: "",
    place: {
      name: "",
    },
    created_at: "",
  });
  const [selectPerPage, setSelectPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [selectType, setSelectType] = useState("");
  const [reportNumber, setReportNumber] = useState(0);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const timeOut = 1000;
  const allTypes = [
    { id: 0, name: "The place is a spam" },
    { id: 1, name: "The place has been shutdown" },
    { id: 2, name: "Description is incorrect" },
    { id: 3, name: "It doesn't exist anymore" },
    { id: 4, name: "It's inappropriate" },
    { id: 5, name: "Other" },
  ];

  const handleSearchForm = (e) => {
    setCurrentPage(1);
    getReports(1);
    setCount(1);
    e.preventDefault();
  };

  const toggleReportModal = (state, report = {}) => {
    if (report.length !== 0) {
      setSelectedReport(report);
    }

    if (!state) {
      setSuccessMessage(false);
      setErrorMessage(false);
      setSelectedReport({
        user: {
          name: "",
        },
        type: "",
        description: "",
        place: {
          name: "",
        },
        created_at: "",
      });
    }
    setReportModal({
      open: state,
    });
  };

  const handleDescription = (value) => {
    if (value.length > 75) {
      return value.substring(0, 72) + "...";
    }
    return value;
  };

  const getReports = (page) => {
    setCurrentPage(page);
    axios
      .get(
        `/admin/user/${urlParams.id}/reports?page=${page}&perPage=${selectPerPage}&search=${search}&type=${selectType}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setReportNumber(response.data.reportCount);
        setMax(Math.ceil(response.data.reportCount / selectPerPage));
        return setReports(response.data.reports);
      })
      .catch((error) => {
        checkJwt(error);
      });
  };

  const toggleDeleteModal = (state, id = null) => {
    if (id) {
      setReportId(id);
    }

    if (!state) {
      setReportId(null);
    }

    setDeleteModal({
      open: state,
    });
  };

  const handleDeleteReport = () => {
    setShowSpinner(true);
    setTimeout(() => {
      setShowSpinner(false);
    }, timeOut);
    axios
      .delete(`/reports/${reportId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setTimeout(() => {
          setErrorMessage(false);
          setSuccessMessage(true);
          let rep = reports.filter((r) => r.id !== reportId);
          /* console.log('rep length', rep.length);
        console.log('currentPage', currentPage);
        console.log('count', count); */
          if (currentPage !== 1 && rep.length === 0) {
            getReports(currentPage - 1);
            setCount(count - 1);
          } else {
            setReports(rep);
          }
        }, timeOut);
      })
      .catch((error) => {
        checkJwt(error);
        setTimeout(() => {
          setErrorMessage(true);
        }, timeOut);
      });
  };

  const GetFirstReports = () => {
    useEffect(() => {
      setCurrentPage(1);
      axios
        .get(`/admin/user/${urlParams.id}/reports?page=1&perPage=10`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setReportNumber(response.data.reportCount);
          setMax(Math.ceil(response.data.reportCount / 10));
          return setReports(response.data.reports);
        })
        .catch((error) => {
          checkJwt(error);
        });
    }, []);
  };

  GetFirstReports();

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={deleteModal.open}
        toggle={(e) => {
          toggleDeleteModal(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Delete report
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleDeleteModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Alert
          color="success"
          style={{
            margin: "0 20px 20px 20px",
            display: successMessage ? "flex" : "none",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>Report has been successfully deleted</span>
          <i className="fa fa-check"></i>
        </Alert>
        <Alert
          color="danger"
          style={{
            margin: "0 20px 20px 20px",
            display: errorMessage ? "flex" : "none",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>Something went wrong</span>
          <i className="fa fa-exclamation"></i>
        </Alert>
        <div className={`modal-body ${successMessage ? "d-none" : ""}`}>
          Are you sure you want to delete this report?
        </div>
        {showSpinner ? (
          <div className="d-flex justify-content-center m-4">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : !successMessage ? (
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={(e) => {
                toggleDeleteModal(false);
                e.preventDefault();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                handleDeleteReport();
                e.preventDefault();
              }}
              color="danger"
              type="button"
            >
              Delete
            </Button>
          </div>
        ) : (
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleDeleteModal(false)}
            >
              Close
            </Button>
          </div>
        )}
      </Modal>

      <Modal
        className="modal-lg"
        isOpen={reportModal.open}
        toggle={(e) => {
          toggleReportModal(false);
        }}
      >
        <div
          className="modal-header"
          style={{ alignItems: "center", paddingBottom: 35 + "px" }}
        >
          <h5 className="modal-title" id="exampleModalLabel">
            {selectedReport.description.slice(0, 10) + "..."}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleReportModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col>
              <b>Name of reporter</b>
            </Col>
            <Col>{selectedReport.user.name}</Col>
          </Row>
          <Row>
            <Col>
              <b>Name of reported place</b>
            </Col>
            <Col>{selectedReport.place.name}</Col>
          </Row>
          <Row>
            <Col>
              <b>Type of report</b>
            </Col>
            <Col>{selectedReport.type}</Col>
          </Row>
          <Row>
            <Col>
              <b>Created at</b>
            </Col>
            <Col>
              {selectedReport.created_at
                ? formatDate(selectedReport.created_at, true)
                : ""}
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Row style={{ marginBottom: 20 + "px" }}>
                <Col>
                  <b>Description</b>
                </Col>
              </Row>
              <Row>
                <Col>{selectedReport.description}</Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleReportModal(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Reports</h3>
                <hr />
                <Form onSubmit={handleSearchForm}>
                  <Row>
                    <Col md="4" sm="3">
                      <FormGroup>
                        <InputGroup className="mb-4">
                          <Input
                            placeholder="Search"
                            type="text"
                            name="search"
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              <i className="ni ni-zoom-split-in" />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md="2" sm="3">
                      <FormGroup>
                        <Input
                          type="select"
                          name="perPageSelect"
                          onChange={(e) => {
                            setSelectPerPage(e.target.value);
                          }}
                          value={selectPerPage}
                        >
                          <option key="10" value="10">
                            10
                          </option>
                          <option key="20" value="20">
                            20
                          </option>
                          <option key="50" value="50">
                            50
                          </option>
                          <option key="100" value="100">
                            100
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="2" sm="3">
                      <FormGroup>
                        <Input
                          type="select"
                          name="searchType"
                          onChange={(e) => {
                            setSelectType(e.target.value);
                          }}
                          value={selectType}
                        >
                          <option key="searchTypeDefault" value="" default>
                            All type
                          </option>
                          {allTypes.map((type) => (
                            <option key={type.id} value={type.id}>
                              {type.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="2" sm="3">
                      <FormGroup>
                        <Button color="primary" onClick={handleSearchForm}>
                          Search
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name of reporter</th>
                    <th scope="col">Place name</th>
                    <th scope="col">Type</th>
                    <th scope="col">Description</th>
                    <th scope="col" />
                  </tr>
                </thead>
                {reports.length > 0 ? (
                  <tbody>
                    {reports.map((value, key) => {
                      return (
                        <tr key={value.id}>
                          <td>{value.user.name}</td>
                          <td>{value.place.name}</td>
                          <td>{value.type}</td>
                          <td>{handleDescription(value.description)}</td>

                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => {
                                    toggleReportModal(true, value);
                                    e.preventDefault();
                                  }}
                                >
                                  <i className="fa fa-eye"></i> View
                                </DropdownItem>
                                <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => {
                                    toggleDeleteModal(true, value.id);
                                    e.preventDefault();
                                  }}
                                >
                                  <i className="fa fa-trash"></i> Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="5" className="text-center">
                        This place has no reports
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              {reports.length > 0 ? (
                <CardFooter className="py-4">
                  <nav
                    aria-label="..."
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="mb-0">
                      <b>All:</b> {reportNumber}
                    </p>
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem
                        className={`${count === 1 ? "disabled" : ""}`}
                      >
                        <PaginationLink
                          href=""
                          onClick={(e) => {
                            if (count !== 1) {
                              getReports(count - 1);
                              setCount(count - 1);
                            } else {
                              getReports(1);
                            }

                            e.preventDefault();
                          }}
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "0 20px",
                        }}
                      >
                        {count} / {max}
                      </PaginationItem>
                      <PaginationItem
                        className={`${count === max ? "disabled" : ""}`}
                      >
                        <PaginationLink
                          href="#"
                          onClick={(e) => {
                            if (count === max) {
                              getReports(count);
                            } else {
                              getReports(count + 1);
                              setCount(count + 1);
                            }

                            e.preventDefault();
                          }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PlaceReports;
