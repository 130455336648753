import {
  Card,
  CardHeader,
  CardBody,
  // CardFooter,
  Container,
  Row,
  Button,
  // CardBody,
  FormGroup,
  Form,
  Input,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  /* Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Modal, */
  Modal,
  Alert,
  CardFooter,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import React, { useState, useEffect } from "react";
import axios from "axiosConfig.js";
import { checkJwt } from "helper.js";
import { useParams } from "react-router-dom";
import FormData from "form-data";
import GoogleMaps from "components/GoogleMaps";

const Tables = ({ history }) => {
  const [recurring, setRecurring] = useState(false);
  const urlParams = useParams();
  const [allStates, setAllStates] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [myState, setMyState] = useState("");
  const [myCategory, setMyCategory] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [topPlace, setTopPlace] = useState({
    startDate: "",
    endDate: "",
    id: null,
  });
  const [place, setPlace] = useState({});
  const timeOut = 1000;
  const [topPlaceModal, setTopPlaceModal] = useState(false);
  const [placeId, setPlaceId] = useState();
  const [placeDeleteModal, setPlaceDeleteModal] = useState(false);
  const [deletedAt, setDeletedAt] = useState(null);
  const [successUpdateMessage, setSuccessUpdateMessage] = useState(false);
  const [placeImages, setPlaceImages] = useState([]);
  const [creatorName, setCreatorName] = useState("");
  // const [message, setMessage] = useState('');
  const [errors, setErrors] = useState([]);
  const [placeDeleted, setPlaceDeleted] = useState(false);
  // const [validationError, setValidationError] = useState(false);

  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [address, setAddress] = useState("");
  const [markerPosition, setMarkerPosition] = useState({});
  const [fetchData, setFetchData] = useState(false);

  const onChange = (e, set) => {
    set(e.target.value);
    setMarkerPosition({ lat: Number(lat), lng: Number(lng) });
  };

  const mapCallBack = (lat, lng) => {
    const convertedLat = Number(lat).toFixed(7);
    const convertedLng = Number(lng).toFixed(7);
    setLat(convertedLat);
    setLng(convertedLng);
    setMarkerPosition({ lat: Number(convertedLat), lng: Number(convertedLng) });
  };

  const getCoordinates = async () => {
    console.log(address);
    setFetchData(true);
    try {
      const response = await axios.get(
        "https://nominatim.openstreetmap.org/search",
        {
          params: {
            q: address,
            format: "json",
            limit: 1,
          },
        }
      );

      if (response.data.length > 0) {
        const { lat, lon } = response.data[0];
        const latitude = Number(lat).toFixed(7);
        const longitude = Number(lon).toFixed(7);
        setLat(latitude);
        setLng(longitude);
        setMarkerPosition({ lat: Number(latitude), lng: Number(longitude) });
      } else {
        console.log("No results found");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setFetchData(false);
  };

  const toggleTopPlaceModal = (state, id = null) => {
    if (state) {
      setPlaceId(id);
      axios
        .get(`/admin/place/${id}/top-place`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          const startDate = response.data.start_date.split("T")[0];
          const endDate = response.data.end_date.split("T")[0];
          setTopPlace({
            startDate,
            endDate,
            id: response.data.id,
          });
        })
        .catch((error) => {
          checkJwt(error);
        });
    } else {
      setTimeout(() => {
        setTopPlace({
          startDate: "",
          endDate: "",
          id: null,
        });
        setPlaceId(null);
        setSuccessMessage(false);
        setErrorMessage(false);
      }, 500);
    }

    setTopPlaceModal({
      open: state,
    });
  };

  const handleTopPlaceForm = (e) => {
    setShowSpinner(true);
    setTimeout(() => {
      setShowSpinner(false);
    }, timeOut);
    if (topPlace.startDate === "") {
      axios
        .post(
          `/admin/place/${placeId}/top-place`,
          {
            startDate: e.target.startDate.value,
            endDate: e.target.endDate.value,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setTimeout(() => {
            setErrorMessage(false);
            setSuccessMessage(true);
          }, timeOut);
          /* setTimeout(() => {
          toggleTopPlaceModal(false)
          setSuccessMessage(false);
        }, 3000); */
        })
        .catch((error) => {
          checkJwt(error);
          setTimeout(() => {
            setErrorMessage(true);
          }, timeOut);
        });
    } else {
      axios
        .patch(
          `/admin/place/top-place/${topPlace.id}`,
          {
            startDate: e.target.startDate.value,
            endDate: e.target.endDate.value,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setTimeout(() => {
            setErrorMessage(false);
            setSuccessMessage(true);
          }, timeOut);
          /* setTimeout(() => {
          toggleTopPlaceModal(false)
          setSuccessMessage(false);
        }, 3000); */
        })
        .catch((error) => {
          checkJwt(error);
          setTimeout(() => {
            setErrorMessage(true);
          }, timeOut);
        });
    }
    e.preventDefault();
  };

  const handleRecurring = (e) => {
    setRecurring(!recurring);
  };

  const togglePlaceDeleteModal = (state) => {
    if (!state) {
      setTimeout(() => {
        setSuccessMessage(false);
        setErrorMessage(false);
      }, 500);
    }

    setPlaceDeleteModal({
      open: state,
    });
  };

  const handlePlaceDelete = () => {
    setShowSpinner(true);
    setTimeout(() => {
      setShowSpinner(false);
    }, timeOut);
    axios
      .delete(`/places/${urlParams.id}`)
      .then((response) => {
        setTimeout(() => {
          setErrorMessage(false);
          setSuccessMessage(true);
          setPlaceDeleted(true);
          setPlaceImages([]);
          setDeletedAt(new Date().toLocaleString().slice(0, 13));
        }, timeOut);
      })
      .catch((error) => {
        setTimeout(() => {
          setErrorMessage(true);
        }, timeOut);
        checkJwt(error);
      });
  };

  const handlePlaceForm = (e) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    e.preventDefault();
    let deletedPhotos = [];
    if (placeImages.length > 0) {
      for (let i = 0; i < placeImages.length; i++) {
        if (e.target["image" + i].checked) {
          e.target["image" + i].checked = false;
          deletedPhotos.push(placeImages[i].id);
        }
      }
    }

    let placeImagesLocal = [];

    let otherId = allCategories.find((c) => c.name === "Other").id;
    let placeObj = {
      user_id: place.user_id,
      location_id: !isNaN(e.target.state.value)
        ? Number(e.target.state.value)
        : null,
      category_id: !isNaN(e.target.category.value)
        ? Number(e.target.category.value)
        : otherId,
      name: e.target.name.value,
      address: e.target.address.value,
      lat: Number(e.target.latitude.value),
      lng: Number(e.target.longitude.value),
      recurring: Number(recurring),
      website: e.target.website.value,
      description: e.target.description.value,
      deleted_images: deletedPhotos,
    };

    if (e.target.startDate.value !== "") {
      placeObj.start_date = new Date(e.target.startDate.value).toISOString();
    }

    if (e.target.files.files.length > 0) {
      let formData = new FormData();
      for (let i = 0; i < e.target.files.files.length; i++) {
        formData.append("files", e.target.files.files[i]);
      }

      axios
        .post(`/places/${urlParams.id}/upload-image`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.length > 0) {
            placeImagesLocal = placeImages.filter(
              (value, index, array) => !deletedPhotos.includes(value.id)
            );
            placeImagesLocal.push(...response.data);
            setPlaceImages(placeImagesLocal);
          }
        });
    }

    axios
      .patch(`/places/${urlParams.id}`, placeObj, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        let date;
        if (response.data.start_date)
          date = response.data.start_date.split("T")[0];
        response.data.start_date = date;
        setPlace(response.data);
        setMyState(response.data.location_id);
        setMyCategory(response.data.category_id);
        if (response.data.deleted_at !== null) {
          setDeletedAt(response.data.deleted_at.split("T")[0]);
        }

        placeImagesLocal = placeImages.filter(
          (value, index, array) => !deletedPhotos.includes(value.id)
        );
        setPlaceImages(placeImagesLocal);

        setSuccessUpdateMessage(true);
        e.target.files.value = null;
        // setPlaceImages(placeImagesLocal);
        setErrors([]);

        setTimeout(() => {
          setSuccessUpdateMessage(false);
        }, 7000);
      })
      .catch((error) => {
        setErrors([]);
        let errors = [];
        if (typeof error.response.data.errors !== "undefined") {
          error.response.data.errors.forEach((err) => {
            errors[err.param] = err.msg;
          });
          setErrors(errors);
        } else {
          // setMessage(error.response.data.status);
          // setValidationError(true);
        }
        checkJwt(error);
        setTimeout(() => {
          setErrorMessage(true);
        }, timeOut);
      });
  };

  useEffect(() => {
    const GetFirstPlace = () => {
      axios
        .get(`/places/${urlParams.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          let date;
          let newUrl = null;
          if (response.data.start_date)
            date = response.data.start_date.split("T")[0];
          response.data.start_date = date;

          if (response.data.website)
            newUrl = response.data.website.replace(/(^\w+:|^)\/\//, "");
          setPlace({ ...response.data, website: newUrl });
          setLat(response.data.lat);
          setLng(response.data.lng);
          setAddress(response.data.address);
          setMarkerPosition({
            lat: Number(response.data.lat),
            lng: Number(response.data.lng),
          });
          setMyState(response.data.location_id);
          setMyCategory(response.data.category_id);
          setCreatorName(response.data.user.name);
          if (response.data.recurring) setRecurring(true);
          else setRecurring(false);
          if (response.data.deleted_at !== null) {
            setDeletedAt(response.data.deleted_at.split("T")[0]);
          }
          if (response.data.PlaceImage.length > 0) {
            setPlaceImages(response.data.PlaceImage);
          }
          if (response.data.deleted_at !== null) {
            setPlaceDeleted(true);
          }
          axios
            .get("/admin/locations", {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            })
            .then((responseLocation) => {
              let location = responseLocation.data.locations.filter(
                (value) =>
                  value.name_en !== "Hungary" && value.name_en !== "Other"
              );
              setAllStates(location);
            })
            .catch((error) => {
              checkJwt(error);
            });

          axios
            .get("/categories", {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            })
            .then((responseCategories) => {
              setAllCategories(responseCategories.data.categories);
            })
            .catch((error) => {
              checkJwt(error);
            });
        })
        .catch((error) => {
          checkJwt(error);
        });
    };
    GetFirstPlace();
  }, [urlParams.id]);

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={placeDeleteModal.open}
        toggle={(e) => {
          togglePlaceDeleteModal(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Delete Place
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => togglePlaceDeleteModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Alert
          color="success"
          style={{
            margin: "0 20px 20px 20px",
            display: successMessage ? "flex" : "none",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>Place has been successfully deleted</span>
          <i className="fa fa-check"></i>
        </Alert>
        <Alert
          color="danger"
          style={{
            margin: "0 20px 20px 20px",
            display: errorMessage ? "flex" : "none",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>Something went wrong</span>
          <i className="fa fa-exclamation"></i>
        </Alert>
        <div className={`modal-body ${successMessage ? "d-none" : ""}`}>
          Are you sure you want to delete this place?
        </div>
        {showSpinner ? (
          <div className="d-flex justify-content-center m-4">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : !successMessage ? (
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => togglePlaceDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                handlePlaceDelete();
                e.preventDefault();
              }}
              color="danger"
              type="button"
            >
              Delete
            </Button>
          </div>
        ) : (
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => togglePlaceDeleteModal(false)}
            >
              Close
            </Button>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={topPlaceModal.open}
        toggle={(e) => {
          toggleTopPlaceModal(false);
        }}
      >
        <div
          className="modal-header"
          style={{ alignItems: "center", paddingBottom: 35 + "px" }}
        >
          <h5 className="modal-title" id="exampleModalLabel">
            Top place
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleTopPlaceModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Alert
          color="success"
          style={{
            margin: "0 20px 20px 20px",
            display: successMessage ? "flex" : "none",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>Successfully updated</span>
          <i className="fa fa-check"></i>
        </Alert>

        <Alert
          color="danger"
          style={{
            margin: "0 20px 20px 20px",
            display: errorMessage ? "flex" : "none",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>Something went wrong</span>
          <i className="fa fa-exclamation"></i>
        </Alert>

        <Form onSubmit={handleTopPlaceForm}>
          <Row>
            <Col>
              <FormGroup style={{ paddingLeft: 20 + "px" }}>
                <label
                  className="form-control-label"
                  htmlFor="input-place-longitude"
                >
                  Start Date
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-place-longitude"
                  placeholder="Longitude"
                  type="date"
                  name="startDate"
                  autoComplete="off"
                  defaultValue={topPlace.startDate ? topPlace.startDate : ""}
                  onClick={(e) => {
                    setSuccessMessage(false);
                  }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup style={{ paddingRight: 20 + "px" }}>
                <label
                  className="form-control-label"
                  htmlFor="input-place-longitude"
                >
                  End Date
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-place-longitude"
                  placeholder="Longitude"
                  type="date"
                  name="endDate"
                  autoComplete="off"
                  defaultValue={topPlace.endDate ? topPlace.endDate : ""}
                  onClick={(e) => {
                    setSuccessMessage(false);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>

          {showSpinner ? (
            <div className="d-flex justify-content-center m-4">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : !successMessage ? (
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleTopPlaceModal(false)}
              >
                Cancel
              </Button>
              <Button type="submit" color="primary" className="btn btn-primary">
                Save
              </Button>
            </div>
          ) : (
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleTopPlaceModal(false)}
              >
                Close
              </Button>
            </div>
          )}
        </Form>
      </Modal>
      <Header cards={"none"} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <div className="col">
                    <h3 className="mb-0">{place.name} </h3>
                    {deletedAt !== null ? (
                      <p>
                        Deleted at: <b>{deletedAt}</b>
                      </p>
                    ) : null}
                  </div>
                  <div className="col text-right">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        className="btn-icon-only text-light"
                        href="#pablo"
                        role="button"
                        size="sm"
                        color=""
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-ellipsis-v" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem
                          onClick={() => {
                            toggleTopPlaceModal(true, urlParams.id);
                          }}
                        >
                          <i className="fa fa-check"></i> Top place
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            history.push(
                              `/admin/place/${urlParams.id}/reports`
                            );
                          }}
                        >
                          <i className="fa fa-list"></i> Reports
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Alert
                  color="success"
                  isOpen={successUpdateMessage}
                  fade={true}
                >
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Success!</strong> The data has been successfully
                    updated!
                  </span>
                </Alert>

                <p>Created by {creatorName}</p>

                <Form onSubmit={handlePlaceForm}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-place-name"
                        >
                          Name
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-place-name"
                          placeholder="Name"
                          type="text"
                          name="name"
                          autoComplete="off"
                          required
                          defaultValue={place.name}
                          style={{
                            border: `${
                              errors["name"] ? 1 + "px solid red" : "none"
                            }`,
                          }}
                        />
                        <div style={{ color: "red", fontSize: 12 + "px" }}>
                          {errors["name"] ?? ""}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-place-address"
                        >
                          Address
                        </label>
                        <div style={{ display: "flex", gap: "15px" }}>
                          <Input
                            className="form-control-alternative"
                            id="input-place-address"
                            placeholder="Address"
                            type="text"
                            name="address"
                            autoComplete="off"
                            required
                            defaultValue={place.address}
                            style={{
                              border: `${
                                errors["address"] ? 1 + "px solid red" : "none"
                              }`,
                            }}
                            value={address}
                            onChange={(e) => onChange(e, setAddress)}
                          />
                          <Button
                            color="primary"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => getCoordinates()}
                            size="sm"
                            disabled={fetchData}
                          >
                            <div style={{ display: "flex", gap: "10px" }}>
                              {fetchData ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : null}
                              <span>Coords</span>
                            </div>
                          </Button>
                        </div>
                        <div style={{ color: "red", fontSize: 12 + "px" }}>
                          {errors["address"] ?? ""}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-place-state"
                        >
                          State
                        </label>

                        <Input
                          className="form-control-alternative"
                          id="input-place-state"
                          placeholder="State"
                          type="select"
                          name="state"
                          required
                          onChange={(e) => {
                            setMyState(e.target.value);
                          }}
                          value={myState}
                        >
                          {allStates.map((state) => (
                            <option key={state.id} value={state.id}>
                              {state.name_en}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-place-state"
                        >
                          Category
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="user-location"
                          placeholder="State"
                          type="select"
                          name="category"
                          onChange={(e) => {
                            setMyCategory(e.target.value);
                          }}
                          value={myCategory}
                          required
                        >
                          {allCategories.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="3">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-place-latitude"
                        >
                          Latitude
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-place-latitude"
                          placeholder="Latitude"
                          type="number"
                          name="latitude"
                          autoComplete="off"
                          min="24.2"
                          max="49.3"
                          step="0.0000001"
                          required
                          defaultValue={place.lat}
                          value={lat}
                          onChange={(e) => onChange(e, setLat)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-place-longitude"
                        >
                          Longitude
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-place-longitude"
                          placeholder="Longitude"
                          type="number"
                          name="longitude"
                          autoComplete="off"
                          max="-66.9"
                          min="-125"
                          step="0.0000001"
                          required
                          defaultValue={place.lng}
                          value={lng}
                          onChange={(e) => onChange(e, setLng)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-place-longitude"
                        >
                          Start Date
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-place-longitude"
                          placeholder="Longitude"
                          type="date"
                          name="startDate"
                          autoComplete="off"
                          defaultValue={place.start_date}
                          style={{
                            border: `${
                              errors["start_date"] ? 1 + "px solid red" : "none"
                            }`,
                          }}
                        />
                        <div style={{ color: "red", fontSize: 12 + "px" }}>
                          {errors["start_date"] ?? ""}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          paddingRight: 20 + "px",
                        }}
                      >
                        <label
                          className="form-control-label"
                          htmlFor="recurring"
                        >
                          Recurring
                        </label>
                        <label
                          className="custom-toggle"
                          style={{ marginTop: 12 + "px" }}
                        >
                          <Input
                            onChange={handleRecurring}
                            type="checkbox"
                            name="recurring"
                            checked={recurring}
                          />
                          <span className="custom-toggle-slider rounded-circle" />
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-place-website"
                        >
                          Website
                        </label>
                        <InputGroup className="input-group-alternative mb-4">
                          <Input
                            className="form-control-alternative"
                            id="input-place-website"
                            placeholder="Website"
                            type="text"
                            name="website"
                            autoComplete="off"
                            defaultValue={place.website}
                            style={{
                              border: `${
                                errors["website"] ? 1 + "px solid red" : "none"
                              }`,
                            }}
                          />
                          <div style={{ color: "red", fontSize: 12 + "px" }}>
                            {errors["website"] ?? ""}
                          </div>
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              <a
                                rel="noreferrer"
                                href={`${"https://" + place.website}`}
                                target="_blank"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <i className="fa fa-link"></i>
                              </a>
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-place-description"
                        >
                          Description
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-place-desc"
                          placeholder="Description"
                          type="textarea"
                          rows="3"
                          name="description"
                          autoComplete="off"
                          defaultValue={place.description}
                          style={{
                            border: `${
                              errors["description"]
                                ? 1 + "px solid red"
                                : "none"
                            }`,
                          }}
                        />
                        <div style={{ color: "red", fontSize: 12 + "px" }}>
                          {errors["description"] ?? ""}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  {placeImages.length > 0 ? (
                    <Row style={{ marginBottom: 20 + "px" }}>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-place-pics"
                          >
                            Select a picture to delete it.
                          </label>
                          <ul className="custom-profile-checkboxes">
                            {placeImages.map((image, index) => {
                              return (
                                <li key={index}>
                                  <Input
                                    type="checkbox"
                                    id={index}
                                    name={`image${index}`}
                                  />
                                  <label htmlFor={index}>
                                    <img src={image.url} alt="" />
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : null}
                  <Row>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-place-pics"
                        >
                          Upload pictures
                        </label>
                        <Input
                          placeholder="Photo"
                          type="file"
                          id="input-place-pics"
                          multiple
                          accept="image/*"
                          name="files"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                    <GoogleMaps
                      position={markerPosition}
                      callBackFunction={mapCallBack}
                    />
                  </Row>
                  <div
                    className="mt-3"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      color="secondary"
                      data-dismiss="modal"
                      type="button"
                      onClick={(e) => {
                        history.goBack();
                        e.preventDefault();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={placeDeleted ? true : false}
                      type="submit"
                      color="primary"
                      className={`btn btn-primary`}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </CardBody>
              {deletedAt === null ? (
                <CardFooter>
                  <Row>
                    <Col>
                      If you want to delete this place, you can do it by
                      clicking{" "}
                      <span
                        style={{ cursor: "pointer" }}
                        className="text-danger"
                        onClick={(e) => {
                          togglePlaceDeleteModal(true);
                          e.preventDefault();
                        }}
                      >
                        here
                      </span>
                      .
                    </Col>
                  </Row>
                </CardFooter>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Tables;
