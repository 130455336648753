import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Alert,
} from "reactstrap";
import * as React from "react";
import axios from "axiosConfig.js";
import { Link } from "react-router-dom";

const Login = ({ history }) => {
  const [email, setEmail] = React.useState("");
  const [pwd, setPwd] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [showError, setShowError] = React.useState(false);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const loginUser = (e) => {
    e.preventDefault();
    setShowSpinner(true);
    axios
      .post("/login", {
        email: email,
        password: pwd,
        fromAdmin: true,
      })
      .then((response) => {
        setShowSpinner(false);
        let userData = {
          id: response.data.user.id,
          name: response.data.user.name,
          image: response.data.user.image,
        };
        localStorage.setItem("user", JSON.stringify(userData));
        localStorage.setItem("token", response.data.token);

        window.location.href = "/admin/index";
      })
      .catch((error) => {
        setMessage(error.response.data.status);
        setShowError(true);
        setShowSpinner(false);
        /* setTimeout(() => {
        setShowError(false);
      }, 3000) */
      });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPwd(e.target.value);
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: 20 + "px",
            }}
            className="pb-0"
          >
            <img
              alt="hugo-logo"
              style={{ maxWidth: 3.5 + "rem" }}
              src={require("../assets/img/hugo_logo.svg").default}
            />
          </div>

          <CardBody className="px-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Sign in with credentials</small>
            </div>
            <Alert
              color="danger"
              style={{ display: showError ? "block" : "none" }}
            >
              {message}
            </Alert>
            <Form onSubmit={loginUser}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    onChange={handleEmailChange}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    onChange={handlePasswordChange}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                {showSpinner ? (
                  <div className="d-flex justify-content-center mb-4 mt-5">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <Button className="my-4" color="primary" type="submit">
                    Sign in
                  </Button>
                )}
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link className="text-light" to="/auth/forgot">
              <small>Forgot password?</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
