import { Card, CardBody, CardHeader, Col } from "reactstrap";
import * as React from "react";
import axios from "axiosConfig.js";

const Welcome = ({ history }) => {
  const [passwordUrl, setPasswordUrl] = React.useState("");
  const [showLink, setShowLink] = React.useState(false);

  React.useEffect(() => {
    const GetQuerysAndRedirect = new Promise((resolve, reject) => {
      const queryParams = new URLSearchParams(window.location.search);
      let url = "";
      let appUrl = "";
      let password = false;

      if (queryParams.get("user_id") && queryParams.get("token")) {
        password = true;
        appUrl = `hugo://changepassword/${queryParams.get(
          "user_id"
        )}/${queryParams.get("token")}`;
        url = `/auth/new-password?user_id=${queryParams.get(
          "user_id"
        )}&token=${queryParams.get("token")}`;
      } else if (
        queryParams.get("subscription") === "success" &&
        queryParams.get("stripe_id")
      ) {
        appUrl = `hugo://main/subscription/success/${queryParams.get(
          "stripe_id"
        )}`;
        // url = '/auth/welcome';
        axios
          .post("/checkout/save-subscription", {
            user_id: queryParams.get("user_id"),
            stripe_id: queryParams.get("stripe_id"),
          })
          .then((response) => {
            console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (queryParams.get("subscription")) {
        appUrl = "hugo://sub-cancelled";
        // url = '/auth/welcome';
      } else {
        appUrl = "hugo://";
        // url = '/auth/welcome';
        // url = 'https://apps.apple.com/us/app/hello-hugo/id1509867136';
      }

      resolve({
        data: {
          appUrl,
          url,
          password,
        },
      });
    });

    const getMobileOperatingSystem = () => {
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        return "android";
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "ios";
      }

      return "desktop";
    };

    const openApp = (url) => {
      window.location.replace(url);
    };

    GetQuerysAndRedirect.then((response) => {
      if (response.data.password) {
        setPasswordUrl(response.data.url);
        const os = getMobileOperatingSystem();

        if (os !== "ios" && os !== "android") {
          history.push(response.data.url);
          setShowLink(true);
        }
      }
      openApp(response.data.appUrl);
    });
  }, [history]);

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: 20 + "px",
              }}
            >
              <img
                alt="hugo-logo"
                style={{ maxWidth: 6.5 + "rem" }}
                src={require("../assets/img/hugo_logo.svg").default}
              />
            </div>
          </CardHeader>

          <CardBody className="px-lg-5">
            <div className="text-center text-muted mb-2">
              <h1>Welcome</h1>
              {showLink ? <a href={passwordUrl}>Reset in browser</a> : null}
              <div
                className="mt-4"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <a
                  style={{ maxWidth: 7.5 + "rem" }}
                  href="https://apps.apple.com/us/app/hello-hugo/id1509867136"
                >
                  <img
                    alt="app-store"
                    style={{ width: 100 + "%" }}
                    src={require("../assets/img/apple-btn.svg").default}
                  />
                </a>
                <a
                  style={{ maxWidth: 7.5 + "rem" }}
                  href="https://apps.apple.com/us/app/hello-hugo/id1509867136"
                >
                  <img
                    alt="google-play"
                    style={{ width: 100 + "%" }}
                    src={require("../assets/img/google-btn.svg").default}
                  />
                </a>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Welcome;
