/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Alert,
} from "reactstrap";
import Header from "components/Headers/Header.js";
import { useState } from "react";
import axios from "axiosConfig.js";
import { checkJwt } from "helper.js";

const Notification = () => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const timeOut = 1500;

  const handleSend = (e) => {
    setLoading(true);
    let data = { message };
    axios
      .post("/admin/notification", data)
      .then((response) => {
        setTimeout(() => {
          setMessage("");
          setErrorMessage(false);
          setSuccessMessage(true);
          setTimeout(() => {
            setSuccessMessage(false);
          }, 4000);
          setLoading(false);
        }, timeOut);
      })
      .catch((error) => {
        checkJwt(error);
        setTimeout(() => {
          setSuccessMessage(false);
          setErrorMessage(true);
          setTimeout(() => {
            setErrorMessage(false);
          }, 4000);
          setLoading(false);
        }, timeOut);
      });
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Custom notifications</h3>
              </CardHeader>
              <CardBody className="pt-0">
                <Alert color="success" isOpen={successMessage} fade={true}>
                  <span>Notifications have been sent successfully</span>
                </Alert>
                <Alert color="danger" isOpen={errorMessage} fade={true}>
                  <span>Something went wrong while sending</span>
                </Alert>
                <div className="custom-message">
                  <div className="custom-message-icon">
                    <i className="fa fa-exclamation"></i>
                  </div>
                  <div className="custom-message-text">
                    Attention! You're sending this notification to{" "}
                    <strong>all</strong> users!
                  </div>
                </div>
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="customText">
                          Custom notification message
                        </Label>
                        <Input
                          name="customText"
                          type="textarea"
                          maxLength={256}
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-end">
                    <Col md="3">
                      {loading ? (
                        <div className="d-flex justify-content-center my-2">
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <Button
                          className="btn btn-primary w-100"
                          color="primary"
                          onClick={handleSend}
                        >
                          Send
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Notification;
