/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import Profile from "views/Profile.js";
// import Maps from "views/examples/Maps.js";
import Login from "views/Login.js";
import Welcome from "views/Welcome.js";
import Forgot from "views/Forgot.js";
import NewPassword from "views/NewPassword.js";
import Notification from "views/Notification.js";
// import Tables from "views/examples/Tables.js";
// import Icons from "views/examples/Icons.js";
import Users from "views/Users.js";
import Donations from "views/Donations.js";
import UserReports from "views/UserReports.js";
import PlaceReports from "views/PlaceReports.js";
import Reports from "views/Reports.js";
import Places from "views/Places.js";
import Place from "views/Place.js";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-common",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: "fa fa-users text-primary",
    component: Users,
    layout: "/admin",
  },
  {
    path: "/user/:id/reports",
    name: "UserReports",
    icon: "fa fa-users text-primary",
    component: UserReports,
    layout: "/admin",
  },
  {
    path: "/reports",
    name: "Reports",
    icon: "ni ni-bullet-list-67 text-red",
    component: Reports,
    layout: "/admin",
  },
  {
    path: "/place/:id/reports",
    name: "PlaceReports",
    icon: "ni ni-pin-3 text-success",
    component: PlaceReports,
    layout: "/admin",
  },
  {
    path: "/places",
    name: "Places",
    icon: "ni ni-pin-3 text-success",
    component: Places,
    layout: "/admin",
  },
  {
    path: "/donations",
    name: "Donations",
    icon: "ni ni-pin-3 text-success",
    component: Donations,
    layout: "/admin",
  },
  {
    path: "/place/:id",
    name: "Place",
    icon: "ni ni-pin-3 text-success",
    component: Place,
    layout: "/admin",
  },
  {
    path: "/user-profile/:id",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/notification",
    name: "Notification",
    icon: "ni ni-bell-55 text-red",
    component: Notification,
    layout: "/admin",
  },
  /*   {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: Icons,
    layout: "/admin",
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: Maps,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/admin",
  }, */

  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/new-password",
    name: "NewPassword",
    icon: "ni ni-key-25 text-info",
    component: NewPassword,
    layout: "/auth",
  },
  {
    path: "/forgot",
    name: "Forgot Password",
    icon: "ni ni-circle-08 text-pink",
    component: Forgot,
    layout: "/auth",
  },
  {
    path: "/welcome",
    name: "Welcome",
    icon: "ni ni-circle-08 text-pink",
    component: Welcome,
    layout: "/auth",
  },
];
export default routes;
