/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";
import * as React from "react";
import axios from "axiosConfig.js";

const Forgot = ({ history }) => {
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [done, setDone] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [showError, setShowError] = React.useState(false);

  const forgotEmail = (e) => {
    e.preventDefault();
    axios
      .post("/admin/forgot-password", {
        email: email,
      })
      .then((response) => {
        setLoading(true);
        setTimeout(() => {
          setDone(true);
          setLoading(false);
          setTimeout(() => {
            history.push("/auth/login");
          }, 4000);
        }, 2000);
      })
      .catch((error) => {
        setMessage(error.response.data.status);
        setShowError(true);
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  return (
    <Col lg="5" md="7">
      <Card className="bg-secondary shadow border-0">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: 20 + "px",
          }}
          className="pb-0"
        >
          <img
            alt="hugo-logo"
            style={{ maxWidth: 3.5 + "rem" }}
            src={require("../assets/img/hugo_logo.svg").default}
          />
        </div>

        <CardBody className="px-lg-5">
          {!loading ? (
            !done ? (
              showError ? (
                <div className="text-center text-muted mb-4">
                  <small className="text-danger">{message}</small>
                </div>
              ) : (
                <div className="text-center text-muted mb-4">
                  <small>
                    Please enter your email address and we'll send you
                    instruction on how to reset your password
                  </small>
                </div>
              )
            ) : (
              <div className="text-center text-muted mb-4">
                <small className="text-success">
                  We're done! Check your emails!
                  <br />
                  We're redirect you to login in a few seconds...
                </small>
              </div>
            )
          ) : (
            <div className="px-lg-5 text-center">
              <div className="spinner-border mb-4"></div>
            </div>
          )}

          <Form onSubmit={forgotEmail}>
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Email"
                  type="email"
                  autoComplete="new-email"
                  onChange={handleEmailChange}
                  required
                />
              </InputGroup>
            </FormGroup>
            <div className="text-center">
              <Button className="my-4" color="primary" type="submit">
                Send
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Forgot;
