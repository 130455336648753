// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Modal,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { useState, useEffect } from "react";
import axios from "axiosConfig.js";
import { checkJwt, handleLongText } from "helper.js";
import { useParams, Link } from "react-router-dom";

const Profile = ({ history }) => {
  const localStorageUser = JSON.parse(localStorage.getItem("user"));
  const [addModal, setAddModal] = useState({});
  const [addPlaceModal, setAddPlaceModal] = useState({});
  const [deleteModal, setDeleteModal] = useState({});
  const [userDeleteModal, setUserDeleteModal] = useState({});
  const [user, setUser] = useState({});
  const [locationName, setLocationName] = useState("");
  const [toggle, setToggle] = useState(false);
  const [adminToggle, setAdminToggle] = useState(false);
  const [recurring, setRecurring] = useState(false);
  const urlParams = useParams();
  const [photo, setPhoto] = useState();
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [places, setPlaces] = useState([]);
  const [max, setMax] = useState(1);
  const [count, setCount] = useState(1);
  const [formChange, setFormChange] = useState(false);
  const [allStates, setAllStates] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [myValue, setMyValue] = useState("");
  const queryParams = new URLSearchParams(window.location.search);
  const addParam = queryParams.get("add");
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const timeOut = 1000;
  const [deletedAt, setDeletedAt] = useState(null);
  const [successUploadAlert, setSuccessUploadAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);
  const [allPlaceStates, setAllPlaceStates] = useState([]);
  const [search, setSearch] = useState("");
  const [selectPerPage, setSelectPerPage] = useState(10);
  const [selectCategory, setSelectCategory] = useState("");
  const [selectState, setSelectState] = useState("");
  const [placeNumber, setPlaceNumber] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [clearStartDate, setClearStartDate] = useState("");
  const [clickOnce, setClickOnce] = useState(false);

  const handleSelectedCategory = (value) => {
    setSelectedCategory(value);
    if (Number(value) !== 2) {
      setClearStartDate("");
      delete errors.start_date;
    }
  };

  const handleSearchForm = (e) => {
    getPlaces(1);
    setCount(1);
    e.preventDefault();
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setFormChange(true);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    setFormChange(true);
  };

  const handlePhotoChange = (e) => {
    setPhoto(e.target.files[0]);
  };

  const toggleDeleteModal = (state) => {
    setDeleteModal({
      open: state,
    });
  };

  const toggleUserDeleteModal = (state) => {
    if (!state) {
      setTimeout(() => {
        setSuccessMessage(false);
        setErrorMessage(false);
      }, 500);
    }

    setUserDeleteModal({
      open: state,
    });
  };

  const handleToggle = (e) => {
    setToggle(!toggle);
    setFormChange(true);
  };

  const handleAdminToggle = (e) => {
    setAdminToggle(!adminToggle);
    setFormChange(true);
  };

  const handleRecurring = (e) => {
    setRecurring(!recurring);
  };

  const handlePlaceForm = (e) => {
    setClickOnce(true);
    e.preventDefault();
    let otherId = allCategories.find((c) => c.name === "Other").id;
    let formData = new FormData();
    formData.append("user_id", urlParams.id);
    formData.append(
      "location_id",
      !isNaN(e.target.state.value) ? Number(e.target.state.value) : null
    );
    formData.append(
      "category_id",
      !isNaN(e.target.category.value)
        ? Number(e.target.category.value)
        : otherId
    );
    formData.append("name", e.target.name.value);
    formData.append("address", e.target.address.value);
    formData.append("lat", Number(e.target.latitude.value));
    formData.append("lng", Number(e.target.longitude.value));
    if (
      e.target.startDate.value !== "" ||
      Number(e.target.category.value) === 2
    ) {
      formData.append(
        "start_date",
        new Date(e.target.startDate.value).toISOString()
      );
    }
    // formData.append('start_date', e.target.startDate.value === "" ? null : new Date(e.target.startDate.value).toISOString());
    formData.append("recurring", Number(recurring));
    formData.append("website", e.target.website.value);
    formData.append("description", e.target.description.value);

    if (e.target.files.files.length > 0) {
      for (let i = 0; i < e.target.files.files.length; i++) {
        formData.append("files", e.target.files.files[i]);
      }
    }

    axios
      .post(`/places`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        user.stars = user.stars + 1;
        setErrors([]);
        setSuccessUploadAlert(true);
        getPlaces(count);
        setTimeout(() => {
          setSuccessUploadAlert(false);
          setAddPlaceModal({
            open: false,
          });
          setClickOnce(false);
        }, 5000);
      })
      .catch((error) => {
        setClickOnce(false);
        setErrors([]);
        let errors = [];
        if (typeof error.response.data.errors !== "undefined") {
          error.response.data.errors.forEach((err) => {
            errors[err.param] = err.msg;
          });
          setErrors(errors);
        } else {
          setMessage(error.response.data.status);
          setErrorMessage(true);
          setTimeout(() => {
            setErrorMessage(false);
          }, 5000);
        }
        checkJwt(error);
      });
  };

  const toggleAddModal = (state) => {
    setAddModal({
      open: state,
    });
  };

  const toggleAddPlaceModal = (state) => {
    if (!state) {
      setSuccessUploadAlert(false);
      setErrorMessage(false);
      setErrors([]);
    }

    setAddPlaceModal({
      open: state,
    });
  };

  const saveUserData = (e) => {
    e.preventDefault();
    axios
      .patch(`/admin/user/${urlParams.id}`, {
        email: email,
        name: name,
        notify: toggle,
        state: Number(myValue),
        admin: adminToggle,
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        checkJwt(error);
      });
  };

  const handleUserDelete = () => {
    setShowSpinner(true);
    setTimeout(() => {
      setShowSpinner(false);
    }, timeOut);
    axios
      .delete(`/users/${urlParams.id}`)
      .then((response) => {
        setTimeout(() => {
          setErrorMessage(false);
          setSuccessMessage(true);
          setDeletedAt(new Date().toLocaleDateString());
        }, timeOut);
      })
      .catch((error) => {
        setTimeout(() => {
          setErrorMessage(true);
        }, timeOut);
        checkJwt(error);
      });
  };

  const hangleUploadPhoto = () => {
    let formData = new FormData();
    formData.append("file", photo);
    formData.append("user_id", urlParams.id);

    axios
      .post(`users/upload-image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        toggleAddModal(false);
        window.location.reload();
      });
  };

  const handleDeletePhoto = () => {
    axios
      .delete(`/users/${urlParams.id}/image`)
      .then((response) => {
        console.log(response.data);
        window.location.reload();
      })
      .catch((error) => {
        checkJwt(error);
      });
  };

  const GetUser = async () => {
    useEffect(() => {
      axios
        .get(`/admin/user/${urlParams.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setAddModal({
            open: false,
          });

          setDeleteModal({
            open: false,
          });

          if (addParam) {
            setAddPlaceModal({
              open: true,
            });
          }

          if (allStates.length === 0 || allPlaceStates.length === 0) {
            axios
              .get("/admin/locations", {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              })
              .then((responseLocation) => {
                let location = responseLocation.data.locations.filter(
                  (value) =>
                    value.name_en !== "Hungary" && value.name_en !== "Other"
                );
                setAllPlaceStates(location);
                setAllStates(responseLocation.data.locations);
              })
              .catch((error) => {
                checkJwt(error);
              });
          }

          if (allCategories.length === 0) {
            axios
              .get("/categories", {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              })
              .then((responseCategories) => {
                setAllCategories(responseCategories.data.categories);
              })
              .catch((error) => {
                checkJwt(error);
              });
          }

          if (Number(urlParams.id) === Number(localStorageUser.id)) {
            let userData = {
              id: response.data.user.id,
              name: response.data.user.name,
              image: response.data.user.image,
            };

            localStorage.setItem("user", JSON.stringify(userData));
          }

          setUser(response.data.user);
          setLocationName(response.data.user.location);
          setMyValue(response.data.user.location.id);
          setToggle(response.data.user.notify);
          setEmail(response.data.user.email);
          setName(response.data.user.name);
          if (response.data.user.role === "ADMIN") {
            setAdminToggle(true);
          }
          if (response.data.user.deleted_at !== null)
            setDeletedAt(
              new Date(response.data.user.deleted_at).toLocaleDateString()
            );
          axios
            .get(
              `/admin/uploads?page=1&perPage=10&id=${response.data.user.id}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then((response) => {
              setMax(Math.ceil(response.data.placeCount / 10));
              setPlaceNumber(response.data.placeCount);
              return setPlaces(response.data.places);
            })
            .catch((error) => {
              checkJwt(error);
            });
        })
        .catch((error) => {
          checkJwt(error);
        });
    }, []);
  };

  const getPlaces = (page) => {
    axios
      .get(
        `/admin/uploads?page=${page}&perPage=${selectPerPage}&id=${user.id}&search=${search}&state=${selectState}&category=${selectCategory}`
      )
      .then((response) => {
        setMax(Math.ceil(response.data.placeCount / selectPerPage));
        setPlaceNumber(response.data.placeCount);
        return setPlaces(response.data.places);
      })
      .catch((error) => {
        checkJwt(error);
      });
  };

  GetUser();

  return (
    <>
      <UserHeader user={user} />

      <Modal
        className="modal-dialog-centered"
        isOpen={deleteModal.open}
        toggle={(e) => {
          toggleDeleteModal(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Delete photo
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleDeleteModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          Are you sure you want to delete this photo?
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            color="danger"
            type="button"
            onClick={(e) => {
              handleDeletePhoto();
              e.preventDefault();
            }}
          >
            Delete
          </Button>
        </div>
      </Modal>

      <Modal
        className="modal-dialog-centered"
        isOpen={userDeleteModal.open}
        toggle={(e) => {
          toggleUserDeleteModal(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Delete User
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleUserDeleteModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Alert
          color="success"
          style={{
            margin: "0 20px 20px 20px",
            display: successMessage ? "flex" : "none",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>Account has been successfully deleted</span>
          <i className="fa fa-check"></i>
        </Alert>
        <Alert
          color="danger"
          style={{
            margin: "0 20px 20px 20px",
            display: errorMessage ? "flex" : "none",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>Something went wrong</span>
          <i className="fa fa-exclamation"></i>
        </Alert>
        <div className={`modal-body ${successMessage ? "d-none" : ""}`}>
          Are you sure you want to delete this user?
        </div>
        {showSpinner ? (
          <div className="d-flex justify-content-center m-4">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : !successMessage ? (
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleUserDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                handleUserDelete();
                e.preventDefault();
              }}
              color="danger"
              type="button"
            >
              Delete
            </Button>
          </div>
        ) : (
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleUserDeleteModal(false)}
            >
              Close
            </Button>
          </div>
        )}
      </Modal>

      <Modal
        className="modal-dialog-centered"
        isOpen={addModal.open}
        toggle={(e) => {
          toggleAddModal(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            New photo for {user.name}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleAddModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Form>
          <FormGroup>
            <Input
              placeholder="Photo"
              type="file"
              autoComplete="photo"
              onChange={handlePhotoChange}
              style={{ padding: 0 + " " + 20 + "px" }}
              accept="image/png"
            />
          </FormGroup>
        </Form>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleAddModal(false)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="button"
            onClick={(e) => {
              hangleUploadPhoto();
              e.preventDefault();
            }}
          >
            Save
          </Button>
        </div>
      </Modal>

      <Modal
        className="modal-dialog-centered modal-lg"
        isOpen={addPlaceModal.open}
        toggle={(e) => {
          toggleAddPlaceModal(false);
        }}
      >
        <div
          className="modal-header"
          style={{ alignItems: "center", paddingBottom: 35 + "px" }}
        >
          <h5 className="modal-title" id="exampleModalLabel">
            Upload a new place for {user.name}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleAddPlaceModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Alert
          style={{ marginLeft: `${20}px`, marginRight: `${20}px` }}
          color="success"
          isOpen={successUploadAlert}
          fade={true}
        >
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Success!</strong> The place has been uploaded successfully!
          </span>
        </Alert>
        <Alert
          style={{ marginLeft: `${20}px`, marginRight: `${20}px` }}
          color="danger"
          isOpen={errorMessage}
          fade={true}
        >
          <span className="alert-inner--text">{message}</span>
        </Alert>
        <Form onSubmit={handlePlaceForm}>
          <Row>
            <Col>
              <FormGroup style={{ paddingLeft: 20 + "px" }}>
                <label
                  className="form-control-label"
                  htmlFor="input-place-name"
                >
                  Name
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-place-name"
                  placeholder="Name"
                  type="text"
                  name="name"
                  autoComplete="off"
                  required
                  style={{
                    border: `${errors["name"] ? 1 + "px solid red" : "none"}`,
                  }}
                />
                <div style={{ color: "red", fontSize: 12 + "px" }}>
                  {errors["name"] ?? ""}
                </div>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup style={{ paddingRight: 20 + "px" }}>
                <label
                  className="form-control-label"
                  htmlFor="input-place-address"
                >
                  Address
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-place-address"
                  placeholder="Address"
                  type="text"
                  name="address"
                  autoComplete="off"
                  required
                  style={{
                    border: `${
                      errors["address"] ? 1 + "px solid red" : "none"
                    }`,
                  }}
                />
                <div style={{ color: "red", fontSize: 12 + "px" }}>
                  {errors["address"] ?? ""}
                </div>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup style={{ paddingLeft: 20 + "px" }}>
                <label
                  className="form-control-label"
                  htmlFor="input-place-state"
                >
                  State
                </label>

                <Input
                  className="form-control-alternative"
                  id="input-place-state"
                  placeholder="State"
                  type="select"
                  name="state"
                  required
                >
                  <option key="stateDefault" value="" default>
                    --- Select state ---
                  </option>
                  {allPlaceStates.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name_en}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup style={{ paddingRight: 20 + "px" }}>
                <label
                  className="form-control-label"
                  htmlFor="input-place-state"
                >
                  Category
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-place-state"
                  placeholder="State"
                  type="select"
                  name="category"
                  required
                  onChange={(e) => handleSelectedCategory(e.target.value)}
                >
                  <option key="categoryDefault" value="" default>
                    --- Select category ---
                  </option>
                  {allCategories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="3">
              <FormGroup style={{ paddingLeft: 20 + "px" }}>
                <label
                  className="form-control-label"
                  htmlFor="input-place-latitude"
                >
                  Latitude
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-place-latitude"
                  placeholder="Latitude"
                  type="number"
                  name="latitude"
                  autoComplete="off"
                  min="24.2"
                  max="49.3"
                  step="0.0000001"
                  required
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-place-longitude"
                >
                  Longitude
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-place-longitude"
                  placeholder="Longitude"
                  type="number"
                  name="longitude"
                  autoComplete="off"
                  max="-66.9"
                  min="-125"
                  step="0.0000001"
                  required
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-place-longitude"
                >
                  Start Date
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-place-longitude"
                  placeholder="Longitude"
                  type="date"
                  name="startDate"
                  autoComplete="off"
                  required={Number(selectedCategory) === 2 ? true : false}
                  style={{
                    border: `${
                      errors["start_date"] ? 1 + "px solid red" : "none"
                    }`,
                  }}
                  onChange={(e) => setClearStartDate(e.target.value)}
                  value={clearStartDate}
                />
                <div style={{ color: "red", fontSize: 12 + "px" }}>
                  {errors["start_date"] ?? ""}
                </div>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup
                style={{
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingRight: 20 + "px",
                }}
              >
                <label className="form-control-label" htmlFor="recurring">
                  Recurring
                </label>
                <label
                  className="custom-toggle"
                  style={{ marginTop: 12 + "px" }}
                >
                  <Input
                    onChange={handleRecurring}
                    type="checkbox"
                    name="recurring"
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup style={{ padding: 0 + " " + 20 + "px" }}>
                <label
                  className="form-control-label"
                  htmlFor="input-place-website"
                >
                  Website
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-place-website"
                  placeholder="Website"
                  type="text"
                  name="website"
                  autoComplete="off"
                  style={{
                    border: `${
                      errors["website"] ? 1 + "px solid red" : "none"
                    }`,
                  }}
                />
                <div style={{ color: "red", fontSize: 12 + "px" }}>
                  {errors["website"] ?? ""}
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup style={{ padding: 0 + " " + 20 + "px" }}>
                <label
                  className="form-control-label"
                  htmlFor="input-place-description"
                >
                  Description
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-place-desc"
                  placeholder="Description"
                  type="textarea"
                  rows="3"
                  name="description"
                  autoComplete="off"
                  style={{
                    border: `${
                      errors["description"] ? 1 + "px solid red" : "none"
                    }`,
                  }}
                />
                <div style={{ color: "red", fontSize: 12 + "px" }}>
                  {errors["description"] ?? ""}
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup style={{ padding: 0 + " " + 20 + "px" }}>
                <label
                  className="form-control-label"
                  htmlFor="input-place-pics"
                >
                  Pictures
                </label>
                <Input
                  placeholder="Photo"
                  type="file"
                  id="input-place-pics"
                  multiple
                  accept="image/*"
                  name="files"
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleAddPlaceModal(false)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              className="btn btn-primary"
              disabled={clickOnce ? true : false}
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal>

      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      {user.image === null ? (
                        <img
                          alt="Avatar"
                          className="rounded-circle"
                          style={{ width: "150px", height: "150px" }}
                          src={require("../assets/img/avatar.png").default}
                        />
                      ) : (
                        <img
                          alt="Avatar"
                          className="rounded-circle"
                          src={user.image}
                          style={{ width: "150px", height: "150px" }}
                        />
                      )}
                    </a>
                  </div>
                </Col>
              </Row>
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                <div className="d-flex justify-content-between">
                  <Button
                    className={`mr-4 ${deletedAt !== null ? "disabled" : ""}`}
                    color="info"
                    href="#pablo"
                    onClick={(e) => {
                      toggleAddModal(true);
                      e.preventDefault();
                    }}
                    size="sm"
                  >
                    <i className="fa fa-plus"></i> Photo
                  </Button>
                  <Button
                    className={`float-right ${
                      deletedAt !== null ? "disabled" : ""
                    }`}
                    color="danger"
                    href="#pablo"
                    onClick={(e) => {
                      toggleDeleteModal(true);
                      e.preventDefault();
                    }}
                    size="sm"
                  >
                    <i className="fa fa-trash"></i> Photo
                  </Button>
                </div>
              </CardHeader>
              <CardBody className="pt-0 pt-md-4">
                <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                      <div>
                        <span className="heading">{user.stars}</span>
                        <span className="description">Stars</span>
                      </div>
                      <div>
                        <span className="heading">{user.comments}</span>
                        <span className="description">Comments</span>
                      </div>
                    </div>
                  </div>
                </Row>
                <div className="text-center">
                  <h3>{user.name}</h3>
                  <div className="h5 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    {locationName.name_en}
                  </div>

                  <hr className="my-4" />
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>
                    <p>
                      Registered at{" "}
                      <b>{new Date(user.created_at).toLocaleDateString()}</b>
                    </p>
                    {deletedAt !== null ? (
                      <p>
                        Deleted at <b>{deletedAt}</b>
                      </p>
                    ) : null}
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                              style={{ display: "flex" }}
                            >
                              Name
                            </label>
                            <Input
                              disabled={deletedAt !== null ? true : false}
                              className="form-control-alternative"
                              defaultValue={user.name}
                              id="input-username"
                              placeholder="Name"
                              type="text"
                              onChange={handleNameChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="email"
                              style={{ display: "flex" }}
                            >
                              Email
                            </label>
                            <Input
                              disabled={deletedAt !== null ? true : false}
                              className="form-control-alternative"
                              defaultValue={user.email}
                              id="email"
                              placeholder="Email"
                              type="email"
                              onChange={handleEmailChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="user-location"
                              style={{ display: "flex" }}
                            >
                              State
                            </label>
                            {/* <Select
                          value={stateArray.value}
                          options={stateArray}
                          defaultValue={stateArray[3]}
                        /> */}
                            <Input
                              disabled={deletedAt !== null ? true : false}
                              className="form-control-alternative"
                              id="user-location"
                              placeholder="State"
                              type="select"
                              name="userState"
                              onChange={(e) => {
                                setMyValue(e.target.value);
                                setFormChange(true);
                              }}
                              value={myValue}
                            >
                              {allStates.map((state) => (
                                <option key={state.id} value={state.id}>
                                  {state.name_en}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup style={{ display: "flex" }}>
                            <label
                              className={`custom-toggle ${
                                deletedAt !== null
                                  ? "disabled-form-element"
                                  : null
                              }`}
                            >
                              <input
                                checked={toggle}
                                onChange={handleToggle}
                                type="checkbox"
                                name="noti-checkbox"
                              />
                              <span className="custom-toggle-slider rounded-circle" />
                            </label>

                            <span style={{ marginLeft: 20 + "px" }}>
                              Notification
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup style={{ display: "flex" }}>
                            <label
                              className={`custom-toggle ${
                                deletedAt !== null
                                  ? "disabled-form-element"
                                  : null
                              }`}
                            >
                              <input
                                checked={adminToggle}
                                onChange={handleAdminToggle}
                                type="checkbox"
                                name="admin-checkbox"
                              />
                              <span className="custom-toggle-slider rounded-circle" />
                            </label>

                            <span style={{ marginLeft: 20 + "px" }}>Admin</span>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                  <Link
                    to=""
                    className={`${
                      !formChange || deletedAt !== null ? "disabled" : ""
                    } btn btn-primary`}
                    onClick={saveUserData}
                  >
                    Save
                  </Link>
                  {/* <a className={`${!formChange || deletedAt !== null ? "disabled" : ""} btn btn-primary`} onClick={(e) => {saveUserData; e.preventDefault();}}>Save</a> */}
                </div>
                <hr className="my-4" />
                <Row>
                  {deletedAt === null ? (
                    <Col>
                      If you want to delete this user, you can do it by clicking{" "}
                      <span
                        style={{ cursor: "pointer" }}
                        className="text-danger"
                        onClick={(e) => {
                          toggleUserDeleteModal(true);
                          e.preventDefault();
                        }}
                      >
                        here
                      </span>
                      .
                    </Col>
                  ) : (
                    <Col className="text-center">
                      This Account has been deleted.
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">User uploads</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      className={`${deletedAt !== null ? "disabled" : ""}`}
                      color="primary"
                      href="#pablo"
                      onClick={(e) => {
                        toggleAddPlaceModal(true);
                        e.preventDefault();
                      }}
                      size="sm"
                    >
                      <i className="fa fa-plus"></i> Add
                    </Button>
                  </div>
                </Row>
                <hr />
                <Form>
                  <Row>
                    <Col md="4" sm="3">
                      <FormGroup>
                        <InputGroup className="mb-4">
                          <Input
                            placeholder="Search"
                            type="text"
                            name="search"
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              <i className="ni ni-zoom-split-in" />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md="2" sm="3">
                      <FormGroup>
                        <Input
                          type="select"
                          name="searchCategory"
                          onChange={(e) => {
                            setSelectCategory(e.target.value);
                          }}
                          value={selectCategory}
                        >
                          <option key="searchCategoryDefault" value="" default>
                            All category
                          </option>
                          {allCategories.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="2" sm="3">
                      <FormGroup>
                        <Input
                          type="select"
                          name="searchState"
                          onChange={(e) => {
                            setSelectState(e.target.value);
                          }}
                          value={selectState}
                        >
                          <option key="searchStateDefault" value="" default>
                            All state
                          </option>
                          {allStates.map((state) => (
                            <option key={state.id} value={state.id}>
                              {state.name_en}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="2" sm="3">
                      <FormGroup>
                        <Input
                          type="select"
                          name="perPageSelect"
                          onChange={(e) => {
                            setSelectPerPage(e.target.value);
                          }}
                          value={selectPerPage}
                        >
                          <option key="10" value="10">
                            10
                          </option>
                          <option key="20" value="20">
                            20
                          </option>
                          <option key="50" value="50">
                            50
                          </option>
                          <option key="100" value="100">
                            100
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="2" sm="3">
                      <FormGroup>
                        <Button color="primary" onClick={handleSearchForm}>
                          Search
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                {/* <Form onSubmit={handleSearchForm}>
                  <Row>
                    <Col md="4" sm="3">
                      <FormGroup>
                        <InputGroup className="mb-4">
                          <Input placeholder="Search" type="text" name="search" onChange={(e) => setSearch(e.target.value)} />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              <i className="ni ni-zoom-split-in" />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md="2" sm="3">
                      <FormGroup>
                        <Input type="select" name="searchCategory" onChange={(e) => { setSelectCategory(e.target.value); }} value={selectCategory}>
                        <option key="searchCategoryDefault" value="" default>All category</option>
                        {allCategories.map((category) => <option key={category.id} value={category.id}>{category.name}</option>)}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="2" sm="3">
                      <FormGroup>
                        <Input type="select" name="searchState" onChange={(e) => { setSelectState(e.target.value); }} value={selectState}>
                        <option key="searchStateDefault" value="" default>All state</option>
                        {allStates.map((state) => <option key={state.id} value={state.id}>{state.name_en}</option>)}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="2" sm="3">
                      <FormGroup>
                        <Input type="select" name="perPageSelect" onChange={(e) => { setSelectPerPage(e.target.value); }} value={selectPerPage}>
                          <option key="10" value="10">10</option>
                          <option key="20" value="20">20</option>
                          <option key="50" value="50">50</option>
                          <option key="100" value="100">100</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="2" sm="3">
                      <FormGroup>
                        <Button color="primary" onClick={handleSearchForm}>Search</Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form> */}
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Address</th>
                    <th scope="col">State</th>
                    <th scope="col">Category</th>
                    <th scope="col" />
                  </tr>
                </thead>
                {places.length > 0 ? (
                  <tbody>
                    {places.map((value, key) => {
                      return (
                        <tr key={value.id}>
                          <td
                            className={`${
                              value.deleted_at !== null ? "text-red" : ""
                            }`}
                          >
                            {handleLongText(value.name, 40)}
                          </td>
                          <td
                            className={`${
                              value.deleted_at !== null ? "text-red" : ""
                            }`}
                          >
                            {handleLongText(value.address, 40)}
                          </td>
                          <td
                            className={`${
                              value.deleted_at !== null ? "text-red" : ""
                            }`}
                          >
                            {value.location.name_en}
                          </td>
                          <td
                            className={`${
                              value.deleted_at !== null ? "text-red" : ""
                            }`}
                          >
                            {value.category.name}
                          </td>

                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  onClick={() => {
                                    history.push(`/admin/place/${value.id}`);
                                  }}
                                >
                                  <i className="fa fa-eye"></i> View
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    history.push(
                                      `/admin/place/${value.id}/reports`
                                    );
                                  }}
                                >
                                  <i className="fa fa-list"></i> Reports
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="5" className="text-center">
                        This user has no uploads
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              {places.length > 0 ? (
                <CardFooter className="py-4">
                  <nav
                    aria-label="..."
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="mb-0">
                      <b>All:</b> {placeNumber}
                    </p>
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem
                        className={`${count === 1 ? "disabled" : ""}`}
                      >
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => {
                            if (count !== 1) {
                              getPlaces(count - 1);
                              setCount(count - 1);
                            } else {
                              getPlaces(1);
                            }

                            e.preventDefault();
                          }}
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "0 20px",
                        }}
                      >
                        {count} / {max}
                      </PaginationItem>
                      <PaginationItem
                        className={`${count === max ? "disabled" : ""}`}
                      >
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => {
                            if (count === max) {
                              getPlaces(count);
                            } else {
                              getPlaces(count + 1);
                              setCount(count + 1);
                            }

                            e.preventDefault();
                          }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              ) : null}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
