const checkJwt = (error) => {
  if (typeof error.response !== "undefined") {
    if (error.response.status === 403 || error.response.status === 401) {
      if (localStorage.getItem("token")) localStorage.removeItem("token");
      if (localStorage.getItem("user")) localStorage.removeItem("user");
      window.location.href = "/auth/login";
    }
  }
};

const formatDate = (date, entire = false) => {
  let d = new Date(date);
  let month = (d.getMonth() + 1).toString();
  let day = d.getDate().toString();
  let year = d.getFullYear();
  let hour = d.getHours();
  let minutes = d.getMinutes();
  let seconds = d.getSeconds();
  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }
  if (entire) return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
  else return `${year}-${month}-${day}`;
};

const handleLongText = (value, number = 75) => {
  if (value.length > number) {
    return value.substring(0, number - 3) + "...";
  }
  return value;
};

export { checkJwt, formatDate, handleLongText };
