/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useState, useEffect } from "react";
import axios from "axiosConfig.js";
import { checkJwt } from "helper.js";

const Tables = () => {
  const [donatinos, setDonations] = useState([]);
  const [max, setMax] = useState(1);
  const [count, setCount] = useState(1);
  // const [lastId, setLastId] = useState('');

  const getDonatinos = (page) => {
    /* axios.get(`/checkout/list?perPage=5&id=${lastId}&first=false`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((response) => {
            setLastId(response.data.lastId)
            return setDonations(response.data.objArray);
        }).catch((error) => {
          checkJwt(error);
        }) */
    axios
      .get(`/admin/donations?page=${page}&perPage=10`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setMax(Math.ceil(response.data.donationCount / 10));
        return setDonations(response.data.donations);
      })
      .catch((error) => {
        checkJwt(error);
      });
  };

  const GetFirstDonations = () => {
    useEffect(() => {
      /* axios.get(`/checkout/list?perPage=5`, {
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }).then((response) => {
            setLastId(response.data.lastId)
            setMax(Math.ceil(response.data.count / 5));
            return setDonations(response.data.objArray);
        }).catch((error) => {
          checkJwt(error);
        }) */
      axios
        .get(`/admin/donations?page=1&perPage=10`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setMax(Math.ceil(response.data.donationCount / 10));
          return setDonations(response.data.donations);
        })
        .catch((error) => {
          checkJwt(error);
        });
    }, []);
  };

  GetFirstDonations();

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Donations</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Stripe Id</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Name</th>
                    <th scope="col">Created</th>
                  </tr>
                </thead>
                {donatinos.length > 0 ? (
                  <tbody>
                    {donatinos.map((value, key) => {
                      return (
                        <tr key={value.id}>
                          <td>{value.stripe_id}</td>
                          <td>${value.amount.toLocaleString()}</td>
                          <td>{value.user.name}</td>
                          <td>{value.created_at.split("T")[0]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="5" className="text-center">
                        There are no donations
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              {donatinos.length > 0 ? (
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem
                        className={`${count === 1 ? "disabled" : ""}`}
                      >
                        <PaginationLink
                          href=""
                          onClick={(e) => {
                            if (count !== 1) {
                              getDonatinos(count - 1);
                              setCount(count - 1);
                            } else {
                              getDonatinos(1);
                            }

                            e.preventDefault();
                          }}
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "0 20px",
                        }}
                      >
                        {count} / {max}
                      </PaginationItem>
                      <PaginationItem
                        className={`${count === max ? "disabled" : ""}`}
                      >
                        <PaginationLink
                          href="#"
                          onClick={(e) => {
                            if (count === max) {
                              getDonatinos(count);
                            } else {
                              getDonatinos(count + 1);
                              setCount(count + 1);
                            }

                            e.preventDefault();
                          }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Tables;
