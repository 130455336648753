/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { useState, useEffect } from "react";
import { checkJwt } from "../../helper.js";

import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import axios from "axiosConfig.js";

const Header = (props) => {
  const [cardDatas, setCardDatas] = useState([]);
  const [donation, setDonation] = useState(0);
  const [monthDonation, setMonthDonation] = useState(0);

  const GetCardDatas = () => {
    useEffect(() => {
      axios
        .get("/admin/dashboard-data", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setCardDatas(response.data);
          setDonation(response.data.donationAmount);
          setMonthDonation(response.data.thisMonthDonation._sum["amount"]);
        })
        .catch((error) => {
          checkJwt(error);
        });
    }, []);
  };

  GetCardDatas();

  const getCards = () => {
    return (
      <Container fluid>
        <div className="header-body">
          {/* Card stats */}
          <Row>
            <Col lg="6" xl="3">
              <Card className="card-stats mb-4 mb-xl-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Places
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {cardDatas.place}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                        <i className="ni ni-pin-3" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        {cardDatas.placePlus < 0 ? (
                          <span className="text-danger mr-2">
                            <i className="fas fa-arrow-down" />{" "}
                            {Math.abs(cardDatas.placePlus)}
                          </span>
                        ) : (
                          <span className="text-success mr-2">
                            <i className="fas fa-arrow-up" />{" "}
                            {cardDatas.placePlus}
                          </span>
                        )}{" "}
                        <span className="text-nowrap">Since last month</span>
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col className="d-flex justify-content-end">
                      <span className="badge-dot mr-1 badge badge-success d-flex align-items-center">
                        <i className="bg-success"></i>
                      </span>
                      {cardDatas.place - cardDatas.deletedPlaces}
                      <span className="badge-dot ml-4 mr-1 badge badge-danger d-flex align-items-center">
                        <i className="bg-danger"></i>
                      </span>
                      {cardDatas.deletedPlaces}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" xl="3">
              <Card className="card-stats mb-4 mb-xl-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Users
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {cardDatas.user}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                        <i className="fas fa-users" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    {cardDatas.userPlus < 0 ? (
                      <span className="text-danger mr-2">
                        <i className="fas fa-arrow-down" />{" "}
                        {Math.abs(cardDatas.userPlus)}
                      </span>
                    ) : (
                      <span className="text-success mr-2">
                        <i className="fas fa-arrow-up" /> {cardDatas.userPlus}
                      </span>
                    )}{" "}
                    <span className="text-nowrap">Since last month</span>
                  </p>
                  <Row className="mt-2">
                    <Col className="d-flex justify-content-end">
                      <span className="badge-dot mr-1 badge badge-success d-flex align-items-center">
                        <i className="bg-success"></i>
                      </span>
                      {cardDatas.user - cardDatas.deletedUsers}
                      <span className="badge-dot ml-4 mr-1 badge badge-danger d-flex align-items-center">
                        <i className="bg-danger"></i>
                      </span>
                      {cardDatas.deletedUsers}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" xl="3">
              <Card className="card-stats mb-4 mb-xl-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Reports
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        {cardDatas.report}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                        <i className="ni ni-bullet-list-67" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    {cardDatas.reportPlus < 0 ? (
                      <span className="text-danger mr-2">
                        <i className="fas fa-arrow-down" />{" "}
                        {Math.abs(cardDatas.reportPlus)}
                      </span>
                    ) : (
                      <span className="text-success mr-2">
                        <i className="fas fa-arrow-up" /> {cardDatas.reportPlus}
                      </span>
                    )}{" "}
                    <span className="text-nowrap">Since last month</span>
                  </p>
                  <Row style={{ height: 32 + "px" }}></Row>
                </CardBody>
              </Card>
            </Col>
            {/* <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Top places
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">{cardDatas.top}</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="fas fa-coins" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-nowrap">Number of active places</span>
                    </p>
                  </CardBody>
                </Card>
              </Col> */}
            <Col lg="6" xl="3">
              <Card className="card-stats mb-4 mb-xl-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Donations
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0">
                        ${donation ? donation.toLocaleString() : 0}
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                        <i className="fas fa-coins" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-muted text-sm">
                    <span className="text-nowrap">
                      <span className="text-success mr-2">
                        ${monthDonation}
                      </span>
                      in this month
                    </span>
                  </p>
                  {/* <Row style={{height: 30 + 'px'}}></Row> */}
                  <Row className="mt-2">
                    <Col className="d-flex justify-content-end">
                      <span className="badge-dot mr-1 badge badge-success d-flex align-items-center">
                        <i className="bg-success"></i>
                      </span>
                      {cardDatas.donationCount}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    );
  };

  return (
    <>
      <div
        className={`header pb-8 pt-5 pt-lg-8 d-flex align-items-center header-image ${
          props.cards !== "none" ? "header-image-h-400" : ""
        }`}
      >
        {/* Mask */}
        <span className="mask bg-gradient-success opacity-8" />
        {props.cards !== "none" ? getCards() : ""}
      </div>
    </>
  );
};

export default Header;
