/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Form,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useState, useEffect } from "react";
import axios from "axiosConfig.js";
import { checkJwt } from "helper.js";

const Users = ({ history }) => {
  const [users, setUsers] = useState([]);
  const [max, setMax] = useState(1);
  const [count, setCount] = useState(1);

  const [selectPerPage, setSelectPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [selectStatus, setSelectStatus] = useState("");
  const [selectState, setSelectState] = useState("");
  const [allStates, setAllStates] = useState([]);
  const [usersNumber, setUsersNumber] = useState(0);
  const [downloadable, setDownloadable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");

  const handleSearchForm = (e) => {
    getUsers(1);
    setCount(1);
    e.preventDefault();
  };

  const getUsers = (page) => {
    axios
      .get(
        `/admin/users?page=${page}&perPage=${selectPerPage}&search=${search}&state=${selectState}&status=${selectStatus}`
      )
      .then((response) => {
        setMax(Math.ceil(response.data.userCount / selectPerPage));
        setUsersNumber(response.data.userCount);
        return setUsers(response.data.users);
      })
      .catch((error) => {
        checkJwt(error);
      });
  };

  const generateCSV = () => {
    setLoading(true);
    axios
      .get("/admin/generate-users-csv")
      .then((response) => {
        setUrl(response.data);
        setDownloadable(true);
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      })
      .catch((error) => {
        checkJwt(error);
      });
  };

  const GetFirstUsers = () => {
    useEffect(() => {
      axios
        .get(`/admin/users?page=1&perPage=10`)
        .then((response) => {
          setMax(Math.ceil(response.data.userCount / 10));
          if (allStates.length === 0) {
            axios
              .get("/admin/locations", {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              })
              .then((responseLocation) => {
                setAllStates(responseLocation.data.locations);
              })
              .catch((error) => {
                checkJwt(error);
              });
          }
          setUsersNumber(response.data.userCount);
          return setUsers(response.data.users);
        })
        .catch((error) => {
          checkJwt(error);
        });
    }, []);
  };

  GetFirstUsers();

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col>
                    <h3 className="mb-0">Users</h3>
                  </Col>
                  <Col className="text-right">
                    {downloadable ? (
                      loading ? (
                        <div className="d-flex justify-content-end">
                          <div
                            style={{ width: 120 + "px" }}
                            className="justify-content-center d-flex"
                          >
                            <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <a
                          className="btn btn-success"
                          href={url}
                          onClick={() => {
                            setDownloadable(false);
                          }}
                        >
                          <i className="fa fa-download"></i> Download
                        </a>
                      )
                    ) : (
                      <Button color="primary" onClick={generateCSV}>
                        Generate CSV
                      </Button>
                    )}
                  </Col>
                </Row>

                <hr className="my-4" />
                <Form onSubmit={handleSearchForm}>
                  <Row>
                    <Col md="4" sm="3">
                      <FormGroup>
                        <InputGroup className="mb-4">
                          <Input
                            placeholder="Search"
                            type="text"
                            name="search"
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              <i className="ni ni-zoom-split-in" />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md="2" sm="3">
                      <FormGroup>
                        <Input
                          type="select"
                          name="searchStatus"
                          onChange={(e) => {
                            setSelectStatus(e.target.value);
                          }}
                          value={selectStatus}
                        >
                          <option key="searchStatusDefault" value="" default>
                            All status
                          </option>
                          <option key="searchStatusActive" value="1">
                            Active
                          </option>
                          <option key="searchStatusDeleted" value="0">
                            Deleted
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="2" sm="3">
                      <FormGroup>
                        <Input
                          type="select"
                          name="searchState"
                          onChange={(e) => {
                            setSelectState(e.target.value);
                          }}
                          value={selectState}
                        >
                          <option key="searchStateDefault" value="" default>
                            All state
                          </option>
                          {allStates.map((state) => (
                            <option key={state.id} value={state.id}>
                              {state.name_en}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="2" sm="3">
                      <FormGroup>
                        <Input
                          type="select"
                          name="perPageSelect"
                          onChange={(e) => {
                            setSelectPerPage(e.target.value);
                          }}
                          value={selectPerPage}
                        >
                          <option key="10" value="10">
                            10
                          </option>
                          <option key="20" value="20">
                            20
                          </option>
                          <option key="50" value="50">
                            50
                          </option>
                          <option key="100" value="100">
                            100
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="2" sm="3">
                      <FormGroup>
                        <Button color="primary" onClick={handleSearchForm}>
                          Search
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">State</th>
                    <th scope="col">Status</th>
                    <th scope="col" />
                  </tr>
                </thead>
                {users.length > 0 ? (
                  <tbody>
                    {users.map((value, key) => {
                      return (
                        <tr key={value.id}>
                          <th
                            scope="row"
                            style={{
                              width: "100px",
                            }}
                          >
                            <Media className="align-items-center justify-content-center">
                              <span className="avatar rounded-circle">
                                {value.image === null ? (
                                  <img
                                    className="rounded-avatar"
                                    alt="..."
                                    src={
                                      require("../assets/img/avatar.png")
                                        .default
                                    }
                                  />
                                ) : (
                                  <img
                                    className="rounded-avatar"
                                    alt="..."
                                    src={value.image}
                                  />
                                )}
                              </span>
                            </Media>
                          </th>
                          <td>{value.name}</td>
                          <td>{value.email}</td>
                          <td>{value.location.name_en}</td>
                          <td>
                            {value.deleted_at === null ? (
                              <Badge color="success" className="badge-dot mr-4">
                                <i className="bg-success" />
                                Active
                              </Badge>
                            ) : (
                              <Badge color="warning" className="badge-dot mr-4">
                                <i className="bg-warning" />
                                Deleted
                              </Badge>
                            )}
                          </td>

                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  onClick={() => {
                                    history.push(
                                      `/admin/user-profile/${value.id}`
                                    );
                                  }}
                                >
                                  <i className="fa fa-eye"></i> View
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    history.push(
                                      `/admin/user/${value.id}/reports`
                                    );
                                  }}
                                >
                                  <i className="fa fa-list"></i> Reports
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="5" className="text-center">
                        There are no users
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              {users.length > 0 ? (
                <CardFooter className="py-4">
                  <nav
                    aria-label="..."
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="mb-0">
                      <b>All:</b> {usersNumber}
                    </p>
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem
                        className={`${count === 1 ? "disabled" : ""}`}
                      >
                        <PaginationLink
                          href=""
                          onClick={(e) => {
                            if (count !== 1) {
                              getUsers(count - 1);
                              setCount(count - 1);
                            } else {
                              getUsers(1);
                            }

                            e.preventDefault();
                          }}
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "0 20px",
                        }}
                      >
                        {count} / {max}
                      </PaginationItem>
                      <PaginationItem
                        className={`${count === max ? "disabled" : ""}`}
                      >
                        <PaginationLink
                          href="#"
                          onClick={(e) => {
                            if (count === max) {
                              getUsers(count);
                            } else {
                              getUsers(count + 1);
                              setCount(count + 1);
                            }

                            e.preventDefault();
                          }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Users;
