import axios from "axios";

// const app_url = "http://localhost:3000"; // Localhost
const app_url = 'https://hugobackend-iriql5b4qq-ew.a.run.app';    // DEV server

const instance = axios.create({ baseURL: app_url });
instance.defaults.headers.common[
  "Authorization"
] = `Bearer ${localStorage.getItem("token")}`;
instance.defaults.headers.common["Content-Type"] = "application/json";

export default instance;
