import React from "react";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "config";

const containerStyle = {
  width: "100%",
  height: "500px",
};

// New York
const center = {
  lat: 40.73061,
  lng: -73.935242,
};

function GoogleMaps({ position, callBackFunction }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY ?? "",
  });

  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = React.useState(null);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={position.lat ? position : center}
      zoom={position.lat ? 17 : 5}
      onLoad={(map) => setMap(map)}
      onUnmount={() => setMap(null)}
      options={{
        mapTypeControl: false,
      }}
      onClick={(ev) => {
        callBackFunction(ev.latLng.lat(), ev.latLng.lng());
      }}
    >
      <MarkerF
        position={{ lat: position?.lat ?? 0, lng: position?.lng ?? 0 }}
      />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(GoogleMaps);
