/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)
// import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

import Header from "components/Headers/Header.js";
import axios from "axiosConfig.js";
import { checkJwt } from "helper.js";

const Index = ({ history }) => {
  // const [activeNav, setActiveNav] = useState(1);
  // const [chartExample1Data, setChartExample1Data] = useState("data1");
  const [placesChartData, setPlacesChartData] = useState([]);
  const [usersChartData, setUsersChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [placeYear, setPlaceYear] = useState(new Date().getFullYear());
  const [userYear, setUserYear] = useState(new Date().getFullYear());

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const handlePlacesByYear = (year) => {
    setPlaceYear(year);
    axios.get(`/admin/places-by-year?year=${year}`).then((response) => {
      setPlacesChartData(response.data.charts.places);
    });
  };

  const handleUsersByYear = (e) => {
    setUserYear(e);
    axios.get(`/admin/users-by-year?year=${e}`).then((response) => {
      setUsersChartData(response.data.charts.users);
    });
  };

  /*  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  }; */

  const handleDescription = (value) => {
    if (value.length > 65) {
      return value.substring(0, 62) + "...";
    }
    return value;
  };

  const GetChartData = async () => {
    useEffect(() => {
      axios
        .get("/admin/dashboard", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setPlacesChartData(response.data.charts.places);
          setUsersChartData(response.data.charts.users);
          setTableData(response.data.tables);
          setReportData(response.data.reports);
        })
        .catch((error) => {
          checkJwt(error);
        });
    }, []);
  };

  GetChartData();

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="7">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="mb-0">Places uploads</h2>
                  </div>
                  <div className="col justify-content-end d-flex">
                    <button
                      className="btn button-custom"
                      onClick={() => {
                        handlePlacesByYear(placeYear - 1);
                      }}
                    >
                      <i className="ni ni-bold-left"></i>
                    </button>
                    <p className="ml-1 mr-1 mb-0 mt-0 d-flex align-items-center">
                      {placeYear}
                    </p>
                    <button
                      className="btn button-custom"
                      onClick={() => {
                        handlePlacesByYear(placeYear + 1);
                      }}
                    >
                      <i className="ni ni-bold-right"></i>
                    </button>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  <Line
                    data={{
                      labels: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                      ],
                      datasets: [
                        {
                          label: "Performance",
                          data: placesChartData,
                        },
                      ],
                    }}
                    options={chartExample1.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="5">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Registration
                    </h6>
                    <h2 className="mb-0">Users</h2>
                  </div>
                  <div className="col justify-content-end d-flex">
                    <button
                      className="btn button-custom"
                      onClick={() => {
                        handleUsersByYear(userYear - 1);
                      }}
                    >
                      <i className="ni ni-bold-left"></i>
                    </button>
                    <p className="ml-1 mr-1 mb-0 mt-0 d-flex align-items-center">
                      {userYear}
                    </p>
                    <button
                      className="btn button-custom"
                      onClick={() => {
                        handleUsersByYear(userYear + 1);
                      }}
                    >
                      <i className="ni ni-bold-right"></i>
                    </button>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  <Bar
                    data={{
                      labels: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                      ],
                      datasets: [
                        {
                          label: "Performance",
                          data: usersChartData,
                        },
                      ],
                    }}
                    options={chartExample2.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Reports</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="/admin/reports"
                      onClick={(e) => {
                        history.push("/admin/reports");
                        e.preventDefault();
                      }}
                      size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Reporter name</th>
                    <th scope="col">Place name</th>
                    <th scope="col">Type</th>
                    <th scope="col">Description</th>
                  </tr>
                </thead>
                {reportData.length > 0 ? (
                  <tbody>
                    {reportData.map((value, key) => {
                      return (
                        <tr key={value.id}>
                          <td>{value.user.name}</td>
                          <td>{value.place.name}</td>
                          <td>{value.type}</td>
                          <td>{handleDescription(value.description)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="5" className="text-center">
                        There are no reports
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Authentication</h3>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Referral</th>
                    <th scope="col">Visitors</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Facebook</th>
                    <td>{tableData.facebook}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">
                          {Math.round(
                            (tableData.facebook / tableData.all) * 100
                          )}
                          %
                        </span>
                        <div>
                          <Progress
                            max={100}
                            value={Math.round(
                              (tableData.facebook / tableData.all) * 100
                            )}
                            barClassName="bg-gradient-primary"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Email</th>
                    <td>{tableData.normal}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">
                          {Math.round((tableData.normal / tableData.all) * 100)}
                          %
                        </span>
                        <div>
                          <Progress
                            max={100}
                            value={Math.round(
                              (tableData.normal / tableData.all) * 100
                            )}
                            barClassName="bg-gradient-success"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Google</th>
                    <td>{tableData.google}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">
                          {Math.round((tableData.google / tableData.all) * 100)}
                          %
                        </span>
                        <div>
                          <Progress
                            max={100}
                            value={Math.round(
                              (tableData.google / tableData.all) * 100
                            )}
                            barClassName="bg-gradient-danger"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Apple</th>
                    <td>{tableData.apple}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">
                          {Math.round((tableData.apple / tableData.all) * 100)}%
                        </span>
                        <div>
                          <Progress
                            max={100}
                            value={Math.round(
                              (tableData.apple / tableData.all) * 100
                            )}
                            barClassName="bg-gradient-dark"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
