import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Button,
  Modal,
  Col,
  FormGroup,
  Form,
  Input,
  Alert,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useState, useEffect } from "react";
import axios from "axiosConfig.js";
import { checkJwt, handleLongText, formatDate } from "helper.js";
import GoogleMaps from "components/GoogleMaps";

const Tables = ({ history }) => {
  const [places, setPlaces] = useState([]);
  const [allPlaceNumber, setAllPlaceNumber] = useState(0);
  const [errors, setErrors] = useState([]);
  const [max, setMax] = useState(1);
  const [count, setCount] = useState(1);
  const localStorageUser = JSON.parse(localStorage.getItem("user"));
  const [topPlaceModal, setTopPlaceModal] = useState(false);
  const [placeId, setPlaceId] = useState();
  const [addPlaceModal, setAddPlaceModal] = useState({});
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [topPlace, setTopPlace] = useState({
    startDate: "",
    endDate: "",
    id: null,
  });
  const [allStates, setAllStates] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [recurring, setRecurring] = useState(false);
  const [successUpdateMessage, setSuccessUpdateMessage] = useState(false);
  const timeOut = 1000;
  const [selectPerPage, setSelectPerPage] = useState(10);
  const [selectCategory, setSelectCategory] = useState("");
  const [selectState, setSelectState] = useState("");
  const [search, setSearch] = useState("");
  const [message, setMessage] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [clearStartDate, setClearStartDate] = useState("");
  const [clickOnce, setClickOnce] = useState(false);
  const [downloadable, setDownloadable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [selectStatus, setSelectStatus] = useState("1");
  const [charCount, setCharCount] = useState(0);
  const [maxLength] = useState(65000);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [address, setAddress] = useState("");
  const [markerPosition, setMarkerPosition] = useState({});
  const [fetchData, setFetchData] = useState(false);

  const onChange = (e, set) => {
    set(e.target.value);
    setMarkerPosition({ lat: Number(lat), lng: Number(lng) });
  };

  const mapCallBack = (lat, lng) => {
    const convertedLat = Number(lat).toFixed(7);
    const convertedLng = Number(lng).toFixed(7);
    setLat(convertedLat);
    setLng(convertedLng);
    setMarkerPosition({ lat: Number(convertedLat), lng: Number(convertedLng) });
  };

  const getCoordinates = async () => {
    setFetchData(true);
    try {
      const response = await axios.get(
        "https://nominatim.openstreetmap.org/search",
        {
          params: {
            q: address,
            format: "json",
            limit: 1,
          },
        }
      );

      if (response.data.length > 0) {
        const { lat, lon } = response.data[0];
        const latitude = Number(lat).toFixed(7);
        const longitude = Number(lon).toFixed(7);
        setLat(latitude);
        setLng(longitude);
        setMarkerPosition({ lat: Number(latitude), lng: Number(longitude) });
      } else {
        console.log("No results found");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setFetchData(false);
  };

  const recalculate = (e) => {
    let description = e.target.value;
    setCharCount(description.length);
  };

  const generateCSV = () => {
    setLoading(true);
    axios
      .get("/admin/generate-places-csv")
      .then((response) => {
        setDownloadable(true);
        setUrl(response.data);
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      })
      .catch((error) => {
        checkJwt(error);
      });
  };

  const handleSelectedCategory = (value) => {
    setSelectedCategory(value);
    if (Number(value) !== 2) {
      setClearStartDate("");
      delete errors.start_date;
    }
  };

  const handleSearchForm = (e) => {
    getPlaces(1);
    setCount(1);
    e.preventDefault();
  };

  const handleRecurring = (e) => {
    setRecurring(!recurring);
  };

  const handlePlaceForm = (e) => {
    setClickOnce(true);
    e.preventDefault();
    let otherId = allCategories.find((c) => c.name === "Other").id;
    let formData = new FormData();
    formData.append("user_id", localStorageUser.id);
    formData.append(
      "location_id",
      !isNaN(e.target.state.value) ? Number(e.target.state.value) : null
    );
    formData.append(
      "category_id",
      !isNaN(e.target.category.value)
        ? Number(e.target.category.value)
        : otherId
    );
    formData.append("name", e.target.name.value);
    formData.append("address", e.target.address.value);
    formData.append("lat", Number(e.target.latitude.value));
    formData.append("lng", Number(e.target.longitude.value));
    if (
      e.target.startDate.value !== "" ||
      Number(e.target.category.value) === 2
    ) {
      formData.append(
        "start_date",
        new Date(e.target.startDate.value).toISOString()
      );
    }
    formData.append("recurring", Number(recurring));
    formData.append("website", e.target.website.value);
    formData.append("description", e.target.description.value);

    if (e.target.files.files.length > 0) {
      for (let i = 0; i < e.target.files.files.length; i++) {
        formData.append("files", e.target.files.files[i]);
      }
    }

    axios
      .post(`/places`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setErrors([]);
        setSuccessUpdateMessage(true);
        getPlaces(count);

        setTimeout(() => {
          setSuccessUpdateMessage(false);
          setAddPlaceModal({
            open: false,
          });
          setClickOnce(false);
        }, 5000);
      })
      .catch((error) => {
        setClickOnce(false);
        setErrors([]);
        let errors = [];
        if (typeof error.response.data.errors !== "undefined") {
          error.response.data.errors.forEach((err) => {
            errors[err.param] = err.msg;
          });
          setErrors(errors);
        } else {
          setMessage(error.response.data.status);
          setErrorMessage(true);
          setTimeout(() => {
            setErrorMessage(false);
          }, 5000);
        }

        checkJwt(error);
      });
  };

  const getPlaces = (page) => {
    axios
      .get(
        `/admin/places?page=${page}&perPage=${selectPerPage}&search=${search}&category=${selectCategory}&state=${selectState}&status=${selectStatus}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setAllPlaceNumber(response.data.placeCount);
        setMax(Math.ceil(response.data.placeCount / selectPerPage));
        return setPlaces(response.data.places);
      })
      .catch((error) => {
        checkJwt(error);
      });
  };

  const toggleTopPlaceModal = (state, id = null) => {
    if (state) {
      setPlaceId(id);
      axios
        .get(`/admin/place/${id}/top-place`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          const startDate = response.data.start_date.split("T")[0];
          const endDate = response.data.end_date.split("T")[0];
          setTopPlace({
            startDate,
            endDate,
            id: response.data.id,
          });
        })
        .catch((error) => {
          checkJwt(error);
        });
    } else {
      setTimeout(() => {
        setTopPlace({
          startDate: "",
          endDate: "",
          id: null,
        });
        setPlaceId(null);
        setSuccessMessage(false);
        setErrorMessage(false);
      }, 500);
    }

    setTopPlaceModal({
      open: state,
    });
  };

  const toggleAddPlaceModal = (state) => {
    if (!state) {
      setSuccessUpdateMessage(false);
      setErrorMessage(false);
      setErrors([]);
      setCharCount(0);
    }
    setAddPlaceModal({
      open: state,
    });
  };

  const handleTopPlaceForm = (e) => {
    setShowSpinner(true);
    setTimeout(() => {
      setShowSpinner(false);
    }, timeOut);
    if (topPlace.startDate === "") {
      axios
        .post(
          `/admin/place/${placeId}/top-place`,
          {
            startDate: e.target.startDate.value,
            endDate: e.target.endDate.value,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setTimeout(() => {
            setErrorMessage(false);
            setSuccessMessage(true);
          }, timeOut);
          /* setTimeout(() => {
          toggleTopPlaceModal(false)
          setSuccessMessage(false);
        }, 3000); */
        })
        .catch((error) => {
          checkJwt(error);
          setTimeout(() => {
            setErrorMessage(true);
          }, timeOut);
        });
    } else {
      axios
        .patch(
          `/admin/place/top-place/${topPlace.id}`,
          {
            startDate: e.target.startDate.value,
            endDate: e.target.endDate.value,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setTimeout(() => {
            setErrorMessage(false);
            setSuccessMessage(true);
          }, timeOut);
          /* setTimeout(() => {
          toggleTopPlaceModal(false)
          setSuccessMessage(false);
        }, 3000); */
        })
        .catch((error) => {
          checkJwt(error);
          setTimeout(() => {
            setErrorMessage(true);
          }, timeOut);
        });
    }
    e.preventDefault();
  };

  const GetFirstPlaces = () => {
    useEffect(() => {
      axios
        .get(`/admin/places?page=1&perPage=10&status=${selectStatus}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setAllPlaceNumber(response.data.placeCount);
          setMax(Math.ceil(response.data.placeCount / 10));

          if (allStates.length === 0) {
            axios
              .get("/admin/locations", {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              })
              .then((responseLocation) => {
                let location = responseLocation.data.locations.filter(
                  (value) =>
                    value.name_en !== "Hungary" && value.name_en !== "Other"
                );
                setAllStates(location);
              })
              .catch((error) => {
                checkJwt(error);
              });
          }

          if (allCategories.length === 0) {
            axios
              .get("/categories", {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              })
              .then((responseCategories) => {
                setAllCategories(responseCategories.data.categories);
              })
              .catch((error) => {
                checkJwt(error);
              });
          }
          return setPlaces(response.data.places);
        })
        .catch((error) => {
          checkJwt(error);
        });
    }, []);
  };

  GetFirstPlaces();

  return (
    <>
      <Modal
        isOpen={topPlaceModal.open}
        toggle={(e) => {
          toggleTopPlaceModal(false);
        }}
      >
        <div
          className="modal-header"
          style={{ alignItems: "center", paddingBottom: 35 + "px" }}
        >
          <h5 className="modal-title" id="exampleModalLabel">
            Top place
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleTopPlaceModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Alert
          color="success"
          style={{
            margin: "0 20px 20px 20px",
            display: successMessage ? "flex" : "none",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>Successfully updated</span>
          <i className="fa fa-check"></i>
        </Alert>

        <Alert
          color="danger"
          style={{
            margin: "0 20px 20px 20px",
            display: errorMessage ? "flex" : "none",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>Something went wrong</span>
          <i className="fa fa-exclamation"></i>
        </Alert>

        <Form onSubmit={handleTopPlaceForm}>
          <Row>
            <Col>
              <FormGroup style={{ paddingLeft: 20 + "px" }}>
                <label
                  className="form-control-label"
                  htmlFor="input-place-longitude"
                >
                  Start Date
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-place-longitude"
                  placeholder="Longitude"
                  type="date"
                  name="startDate"
                  autoComplete="off"
                  defaultValue={topPlace.startDate ? topPlace.startDate : ""}
                  onClick={(e) => {
                    setSuccessMessage(false);
                  }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup style={{ paddingRight: 20 + "px" }}>
                <label
                  className="form-control-label"
                  htmlFor="input-place-longitude"
                >
                  End Date
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-place-longitude"
                  placeholder="Longitude"
                  type="date"
                  name="endDate"
                  autoComplete="off"
                  defaultValue={topPlace.endDate ? topPlace.endDate : ""}
                  onClick={(e) => {
                    setSuccessMessage(false);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>

          {showSpinner ? (
            <div className="d-flex justify-content-center m-4">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : !successMessage ? (
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleTopPlaceModal(false)}
              >
                Cancel
              </Button>
              <Button type="submit" color="primary" className="btn btn-primary">
                Save
              </Button>
            </div>
          ) : (
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleTopPlaceModal(false)}
              >
                Close
              </Button>
            </div>
          )}
        </Form>
      </Modal>

      {/* Add new place MODAL */}
      <Modal
        className="modal-dialog-centered modal-xl"
        isOpen={addPlaceModal.open}
        toggle={(e) => {
          toggleAddPlaceModal(false);
        }}
      >
        <div
          className="modal-header"
          style={{ alignItems: "center", paddingBottom: 35 + "px" }}
        >
          <h5 className="modal-title" id="exampleModalLabel">
            Upload a new place for {localStorageUser.name}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleAddPlaceModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Alert
          style={{ marginLeft: `${20}px`, marginRight: `${20}px` }}
          color="success"
          isOpen={successUpdateMessage}
          fade={true}
        >
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Success!</strong> The datas has been updated successfully!
          </span>
        </Alert>
        <Alert
          style={{ marginLeft: `${20}px`, marginRight: `${20}px` }}
          color="danger"
          isOpen={errorMessage}
          fade={true}
        >
          <span className="alert-inner--text">{message}</span>
        </Alert>
        <Form onSubmit={handlePlaceForm}>
          <Row>
            <Col>
              <FormGroup style={{ paddingLeft: 20 + "px" }}>
                <label
                  className="form-control-label"
                  htmlFor="input-place-name"
                >
                  Name
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-place-name"
                  placeholder="Name"
                  type="text"
                  name="name"
                  autoComplete="off"
                  required
                  style={{
                    border: `${errors["name"] ? 1 + "px solid red" : "none"}`,
                  }}
                />
                <div style={{ color: "red", fontSize: 12 + "px" }}>
                  {errors["name"] ?? ""}
                </div>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup style={{ paddingRight: 20 + "px" }}>
                <label
                  className="form-control-label"
                  htmlFor="input-place-address"
                >
                  Address
                </label>
                <div style={{ display: "flex", gap: "15px" }}>
                  <Input
                    className="form-control-alternative"
                    id="input-place-address"
                    placeholder="Address"
                    type="text"
                    name="address"
                    required
                    autoComplete="off"
                    style={{
                      border: `${
                        errors["address"] ? 1 + "px solid red" : "none"
                      }`,
                    }}
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  />
                  <Button
                    color="primary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => getCoordinates()}
                    size="sm"
                    disabled={fetchData}
                  >
                    <div style={{ display: "flex", gap: "10px" }}>
                      {fetchData ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : null}
                      <span>Coords</span>
                    </div>
                  </Button>
                </div>

                <div style={{ color: "red", fontSize: 12 + "px" }}>
                  {errors["address"] ?? ""}
                </div>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup style={{ paddingLeft: 20 + "px" }}>
                <label
                  className="form-control-label"
                  htmlFor="input-place-state"
                >
                  State
                </label>

                <Input
                  className="form-control-alternative"
                  id="input-place-state"
                  placeholder="State"
                  type="select"
                  name="state"
                  required
                >
                  <option key="stateDefault" value="" default>
                    --- Select state ---
                  </option>
                  {allStates.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name_en}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup style={{ paddingRight: 20 + "px" }}>
                <label
                  className="form-control-label"
                  htmlFor="input-place-state"
                >
                  Category
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-place-state"
                  placeholder="State"
                  type="select"
                  name="category"
                  required
                  onChange={(e) => handleSelectedCategory(e.target.value)}
                >
                  <option key="categoryDefault" value="" default>
                    --- Select category ---
                  </option>
                  {allCategories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="3">
              <FormGroup style={{ paddingLeft: 20 + "px" }}>
                <label
                  className="form-control-label"
                  htmlFor="input-place-latitude"
                >
                  Latitude
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-place-latitude"
                  placeholder="Latitude"
                  type="number"
                  name="latitude"
                  autoComplete="off"
                  min="24.2"
                  max="49.3"
                  step="0.0000001"
                  required
                  value={lat}
                  onChange={(e) => onChange(e, setLat)}
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-place-longitude"
                >
                  Longitude
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-place-longitude"
                  placeholder="Longitude"
                  type="number"
                  name="longitude"
                  autoComplete="off"
                  max="-66.9"
                  min="-125"
                  step="0.0000001"
                  required
                  value={lng}
                  onChange={(e) => onChange(e, setLng)}
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-place-longitude"
                >
                  Start Date
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-place-longitude"
                  placeholder="Longitude"
                  type="date"
                  name="startDate"
                  autoComplete="off"
                  required={Number(selectedCategory) === 2 ? true : false}
                  style={{
                    border: `${
                      errors["start_date"] ? 1 + "px solid red" : "none"
                    }`,
                  }}
                  onChange={(e) => setClearStartDate(e.target.value)}
                  value={clearStartDate}
                />
                <div style={{ color: "red", fontSize: 12 + "px" }}>
                  {errors["start_date"] ?? ""}
                </div>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup
                style={{
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingRight: 20 + "px",
                }}
              >
                <label className="form-control-label" htmlFor="recurring">
                  Recurring
                </label>
                <label
                  className="custom-toggle"
                  style={{ marginTop: 12 + "px" }}
                >
                  <Input
                    onChange={handleRecurring}
                    type="checkbox"
                    name="recurring"
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup style={{ padding: 0 + " " + 20 + "px" }}>
                <label
                  className="form-control-label"
                  htmlFor="input-place-website"
                >
                  Website
                </label>
                <Input
                  className="form-control-alternative"
                  id="input-place-website"
                  placeholder="Website"
                  type="text"
                  name="website"
                  autoComplete="off"
                  style={{
                    border: `${
                      errors["website"] ? 1 + "px solid red" : "none"
                    }`,
                  }}
                />
                <div style={{ color: "red", fontSize: 12 + "px" }}>
                  {errors["website"] ?? ""}
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup style={{ padding: 0 + " " + 20 + "px" }}>
                <label
                  className="form-control-label"
                  htmlFor="input-place-description"
                >
                  Description ({charCount} / {maxLength})
                </label>
                <Input
                  onChange={recalculate}
                  className="form-control-alternative"
                  id="input-place-desc"
                  placeholder="Description"
                  type="textarea"
                  rows="3"
                  name="description"
                  autoComplete="off"
                  maxLength={maxLength}
                  style={{
                    border: `${
                      errors["description"] ? 1 + "px solid red" : "none"
                    }`,
                  }}
                />
                <div style={{ color: "red", fontSize: 12 + "px" }}>
                  {errors["description"] ?? ""}
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup style={{ padding: 0 + " " + 20 + "px" }}>
                <label
                  className="form-control-label"
                  htmlFor="input-place-pics"
                >
                  Pictures
                </label>
                <Input
                  placeholder="Photo"
                  type="file"
                  id="input-place-pics"
                  multiple
                  accept="image/*"
                  name="files"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row style={{ paddingLeft: "35px", paddingRight: "35px" }}>
            <GoogleMaps
              position={markerPosition}
              callBackFunction={mapCallBack}
            />
          </Row>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleAddPlaceModal(false)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              className="btn btn-primary"
              disabled={clickOnce ? true : false}
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal>
      {/* Add new place MODAL end */}

      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col>
                    {/* <h3 className="mb-0">Places</h3> */}
                    <span
                      style={{
                        fontFamily: "inherit",
                        fontWeight: 600,
                        lineHeight: 1.5,
                        color: "#32325d",
                        fontSize: "1.0625rem",
                      }}
                      className="mb-0"
                    >
                      Places
                    </span>
                    <Button
                      color="primary"
                      onClick={(e) => {
                        toggleAddPlaceModal(true);
                        e.preventDefault();
                      }}
                      size="sm"
                      className="ml-2"
                    >
                      New place
                    </Button>
                  </Col>
                  <Col className="text-right">
                    {downloadable ? (
                      loading ? (
                        <div className="d-flex justify-content-end">
                          <div
                            style={{ width: 120 + "px" }}
                            className="justify-content-center d-flex"
                          >
                            <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <a
                          className="btn btn-success"
                          href={url}
                          onClick={() => {
                            setDownloadable(false);
                          }}
                        >
                          <i className="fa fa-download"></i> Download
                        </a>
                      )
                    ) : (
                      <Button color="primary" onClick={generateCSV}>
                        Generate CSV
                      </Button>
                    )}
                  </Col>
                </Row>
                <hr className="my-4" />
                <Form onSubmit={handleSearchForm}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <InputGroup className="mb-4">
                          <Input
                            placeholder="Search"
                            type="text"
                            name="search"
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              <i className="ni ni-zoom-split-in" />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2" sm="3">
                      <FormGroup>
                        <Input
                          type="select"
                          name="searchStatus"
                          onChange={(e) => {
                            setSelectStatus(e.target.value);
                          }}
                          value={selectStatus}
                        >
                          <option key="searchStatusActive" value="1" default>
                            Active
                          </option>
                          <option key="searchStatusDeleted" value="0">
                            Deleted
                          </option>
                          <option key="searchStatusDefault" value="">
                            All
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="3" sm="3">
                      <FormGroup>
                        <Input
                          type="select"
                          name="searchCategory"
                          onChange={(e) => {
                            setSelectCategory(e.target.value);
                          }}
                          value={selectCategory}
                        >
                          <option key="searchCategoryDefault" value="" default>
                            All category
                          </option>
                          {allCategories.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="3" sm="3">
                      <FormGroup>
                        <Input
                          type="select"
                          name="searchState"
                          onChange={(e) => {
                            setSelectState(e.target.value);
                          }}
                          value={selectState}
                        >
                          <option key="searchStateDefault" value="" default>
                            All state
                          </option>
                          {allStates.map((state) => (
                            <option key={state.id} value={state.id}>
                              {state.name_en}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="2" sm="3">
                      <FormGroup>
                        <Input
                          type="select"
                          name="perPageSelect"
                          onChange={(e) => {
                            setSelectPerPage(e.target.value);
                          }}
                          value={selectPerPage}
                        >
                          <option key="10" value="10">
                            10
                          </option>
                          <option key="20" value="20">
                            20
                          </option>
                          <option key="50" value="50">
                            50
                          </option>
                          <option key="100" value="100">
                            100
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="2" sm="12">
                      <FormGroup className="d-flex justify-content-center">
                        <Button
                          color="primary"
                          className="d-flex "
                          onClick={handleSearchForm}
                        >
                          Search
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardHeader>
              <Table
                className="align-items-center table-flush"
                responsive
                style={{ minHeight: 140 + "px" }}
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Address</th>
                    <th scope="col">State</th>
                    <th scope="col">Category</th>
                    <th scope="col">Created</th>
                    <th scope="col" />
                  </tr>
                </thead>
                {places.length > 0 ? (
                  <tbody>
                    {places.map((value, key) => {
                      return (
                        <tr key={value.id}>
                          <td
                            className={`${
                              value.deleted_at !== null ? "text-red" : ""
                            }`}
                          >
                            {handleLongText(value.name, 30)}
                          </td>
                          <td
                            className={`${
                              value.deleted_at !== null ? "text-red" : ""
                            }`}
                          >
                            {handleLongText(value.address, 40)}
                          </td>
                          <td
                            className={`${
                              value.deleted_at !== null ? "text-red" : ""
                            }`}
                          >
                            {value.location.name_en !== null
                              ? value.location.name_en
                              : ""}
                          </td>
                          <td
                            className={`${
                              value.deleted_at !== null ? "text-red" : ""
                            }`}
                          >
                            {value.category.name}
                          </td>
                          <td
                            className={`${
                              value.deleted_at !== null ? "text-red" : ""
                            }`}
                          >
                            {formatDate(value.created_at)}
                          </td>

                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  onClick={() => {
                                    history.push("/admin/place/" + value.id);
                                  }}
                                >
                                  <i className="fa fa-edit"></i> Edit
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    history.push(
                                      `/admin/place/${value.id}/reports`
                                    );
                                  }}
                                >
                                  <i className="fa fa-list"></i> Reports
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    toggleTopPlaceModal(true, value.id);
                                  }}
                                >
                                  <i className="fa fa-check"></i> Top place
                                </DropdownItem>
                                {/* <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => {toggleTopPlaceModal(true, value.id); e.preventDefault();}}
                                >
                                <i className="fa fa-trash"></i> Delete
                                </DropdownItem> */}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="5" className="text-center">
                        There are no places
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              {places.length > 0 ? (
                <CardFooter className="py-4">
                  <nav
                    aria-label="..."
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="mb-0">
                      <b>All:</b> {allPlaceNumber}
                    </p>
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem
                        className={`${count === 1 ? "disabled" : ""}`}
                      >
                        <PaginationLink
                          href=""
                          onClick={(e) => {
                            if (count !== 1) {
                              getPlaces(count - 1);
                              setCount(count - 1);
                            } else {
                              getPlaces(1);
                            }

                            e.preventDefault();
                          }}
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "0 20px",
                        }}
                      >
                        {count} / {max}
                      </PaginationItem>
                      <PaginationItem
                        className={`${count === max ? "disabled" : ""}`}
                      >
                        <PaginationLink
                          href="#"
                          onClick={(e) => {
                            if (count === max) {
                              getPlaces(count);
                            } else {
                              getPlaces(count + 1);
                              setCount(count + 1);
                            }

                            e.preventDefault();
                          }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Tables;
