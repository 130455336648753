/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Col,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import * as React from "react";
import axios from "axiosConfig.js";

const NewPassword = () => {
  const [password, setPassword] = React.useState("");
  const [passwordConfirm, setPasswordConfirm] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [showError, setShowError] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const user_id = queryParams.get("user_id");
  const token = queryParams.get("token");
  const handleNewPasswordForm = (e) => {
    e.preventDefault();
    if (password === passwordConfirm) {
      axios
        .patch(`/users/reset-password?user_id=${user_id}&token=${token}`, {
          password: password,
        })
        .then((response) => {
          setMessage("Your password has been changed!");
          setShowSuccess(true);
          setShowError(false);
          setPassword("");
          setPasswordConfirm("");
          setTimeout(() => {
            setShowSuccess(false);
          }, 2000);
        })
        .catch((error) => {
          if (typeof error.response.data.errors !== "undefined") {
            setMessage(error.response.data.errors[0].msg);
          } else {
            setMessage(error.response.data.status);
          }

          setShowError(true);
        });
    } else {
      setMessage("Passwords do not match");
      setShowError(true);
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setPasswordConfirm(e.target.value);
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: 20 + "px",
            }}
            className="pb-0"
          >
            <img
              alt="hugo-logo"
              style={{ maxWidth: 3.5 + "rem" }}
              src={require("../assets/img/hugo_logo.svg").default}
            />
          </div>

          <CardBody className="px-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Reset your password</small>
            </div>
            <Alert
              color="danger"
              style={{ display: showError ? "block" : "none" }}
            >
              {message}
            </Alert>
            <Alert
              color="success"
              style={{ display: showSuccess ? "block" : "none" }}
            >
              {message}
            </Alert>
            <Form onSubmit={handleNewPasswordForm}>
              <FormGroup className="mb-3">
                {/* <Input
                      name="password"
                      placeholder="Password"
                      type="password"
                      autoComplete="password"
                      onChange={handlePasswordChange}
                    /> */}
                <InputGroup className="input-group-alternative mb-4">
                  <Input
                    name="password"
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="password"
                    onChange={handlePasswordChange}
                    value={password}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      onClick={(e) => {
                        setShowPassword(!showPassword);
                        e.preventDefault();
                      }}
                    >
                      {showPassword ? (
                        <i className="fa fa-eye-slash" />
                      ) : (
                        <i className="fa fa-eye" />
                      )}
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-4">
                  <Input
                    name="password-confirm"
                    placeholder="Password confirmation"
                    type={showConfirmPassword ? "text" : "password"}
                    autoComplete="password-confirm"
                    onChange={handleConfirmPasswordChange}
                    value={passwordConfirm}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      onClick={(e) => {
                        setShowConfirmPassword(!showConfirmPassword);
                        e.preventDefault();
                      }}
                    >
                      {showConfirmPassword ? (
                        <i className="fa fa-eye-slash" />
                      ) : (
                        <i className="fa fa-eye" />
                      )}
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Reset password
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default NewPassword;
